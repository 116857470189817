<script>
  import { createEventDispatcher } from "svelte"
  import { onMount } from "svelte"
  import { isPast, addDays } from "date-fns"
  import { getStatusColor, getStatusText } from "utils/userBundle"
  import { formatDate } from "utils/date"
  import * as API from "apis/backend"
  import Button from "atoms/Button.svelte"
  import StatusTick from "atoms/StatusTick.svelte"
  import SelectWithLabel from "molecules/SelectWithLabel.svelte"
  import TextInput from "molecules/TextInput.svelte"
  import DetailField from "molecules/DetailField.svelte"

  const dispatch = createEventDispatcher()

  const typeOptions = [
    { value: false, label: "Normal" },
    { value: true, label: "Vitalício" }
  ]

  export let bundle
  let userBundle
  export let userId
  let expiresAt
  let bundleId
  let loading = true

  let delta = 0
  let type = false

  $: newExpiresAt = isNaN(delta) ? expiresAt : addDays(expiresAt, delta)

  onMount(async () => {
    bundleId = bundle.id
    userBundle = bundle.userBundle
    expiresAt =
      userBundle && userBundle.expiresAt
        ? new Date(userBundle.expiresAt)
        : new Date()
    type = userBundle && userBundle.expiresAt === null
    loading = false
  })

  function cancel(e) {
    e.preventDefault()
    dispatch("cancel")
  }

  async function submit() {
    const res = await API.patchUserBundle({
      userId,
      bundleId,
      expiresAt: type ? null : newExpiresAt
    })
    if (res[1].status === 204) {
      return dispatch("success")
    }
  }
</script>

{#if !loading}
  <form on:submit|preventDefault={submit} class="block">
    <DetailField label={bundle.name} class="mb-4">
      <div class="flex items-center">
        <StatusTick class="mr-1" color={getStatusColor(userBundle)} />
        {getStatusText(userBundle)}
      </div>
    </DetailField>
    <SelectWithLabel
      label="Tipo"
      class="mb-4"
      bind:value={type}
      options={typeOptions}
      optional={false} />
    {#if !type}
      <TextInput label="Dias +/-" class="mb-4" bind:value={delta} />
      <DetailField label="Novo Status" class="mb-4">
        <div class="flex items-center">
          <StatusTick
            class="mr-1"
            color={getStatusColor({ expiresAt: newExpiresAt, userId })} />
          {getStatusText({ expiresAt: newExpiresAt, userId })}
        </div>
      </DetailField>
    {/if}
    <Button class="mt-2 mb-4">Salvar</Button>
    <Button on:click={cancel}>Cancelar</Button>
  </form>
{/if}
