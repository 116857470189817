<script>
  import { url, goto } from "@sveltech/routify"
  import { onMount } from "svelte"
  import Button from "atoms/Button.svelte"
  import TextInput from "molecules/TextInput.svelte"
  import { mapErrors } from "utils/errors"

  import * as API from "apis/backend"

  export let coupon
  let id
  let code
  let amount
  let duration = 0
  let errors = {}

  onMount(function () {
    if (coupon) {
      id = coupon.id
      code = coupon.code
      amount = coupon.amount
    }
  })

  async function submit() {
    const apiFn = id ? API.putCoupon : API.postCoupon
    const successStatus = id ? 200 : 201
    const payload = {
      ...(id ? { id } : {}),
      code,
      amount,
      duration,
    }
    const [data, meta] = await apiFn(payload)
    if (meta.status === successStatus) {
      return $goto("/cupons")
    }

    errors = mapErrors(data)
  }
</script>

<div class="flex p-4">
  <div class="flex flex-col flex-grow">
    <div class="flex-grow p-4 ml-4 bg-white rounded-md shadow-md">
      <a href={$url('/cupons')}>
        <i class="mb-4 text-lg cursor-pointer fas fa-arrow-left" />
      </a>
      <form on:submit|preventDefault={submit}>
        <TextInput
          label="Código"
          class="mb-6"
          errors={errors.code}
          bind:value={code} />
        <TextInput
          label="Desconto (0 - 100%)"
          class="mb-6"
          errors={errors.amount}
          bind:value={amount} />
        <TextInput
          label="Duração"
          class="mb-6"
          errors={errors.duration}
          bind:value={duration} />
        <Button>Salvar</Button>
      </form>
    </div>
  </div>
</div>
