<script>
  import { goto, url } from "@sveltech/routify"
  import { onMount } from "svelte"
  import Button from "atoms/Button.svelte"
  import TextInput from "molecules/TextInput.svelte"
  import ImageInput from "atoms/ImageInput.svelte"
  import TextAreaInput from "molecules/TextAreaInput.svelte"
  import SelectWithLabel from "molecules/SelectWithLabel.svelte"
  import { mapErrors } from "utils/errors"
  import * as API from "apis/backend"
  import { blobFromBase64, contentTypeFromBase64, isBase64 } from "utils/files"

  export let id
  export let title
  export let description
  export let authors
  export let affiliateUrl
  export let pageCount
  export let image
  export let topicId
  let errors = {}

  const optionsMapper = ({ id: value, name: label }) => ({
    value,
    label
  })

  async function submit() {
    const apiFn = id ? API.putBook : API.postBook
    const successStatus = id ? 200 : 201
    const imageContentType = contentTypeFromBase64(image)
    const payload = {
      ...(id ? { id } : {}),
      title,
      description,
      authors,
      affiliateUrl,
      pageCount,
      imageContentType,
      topicId
    }
    const [data, meta] = await apiFn(payload)
    if (meta.status === successStatus) {
      if (isBase64(image) && data.imageUploadUrl)
        await fetch(data.imageUploadUrl, {
          method: "PUT",
          body: blobFromBase64(image),
          headers: { "Content-Type": imageContentType },
        })
      return $goto("/livros")
    }

    errors = mapErrors(data)
  }

  let topics
  let topicOptions = []

  let fetching = true
  onMount(async function() {
    const [topics] = await API.getTopics({ perPage: 1000 })
    topicOptions = topics.map(optionsMapper)

    if (!topics[0]) return
    if (!topicId) topicId = topics[0].id

    fetching = false
  })
</script>

<div class="flex p-4">
  <div class="flex flex-col flex-grow">
    <div class="flex-grow p-4 ml-4 bg-white rounded-md shadow-md">
      <a href={$url('/perguntas')}>
        <i class="mb-4 text-lg cursor-pointer fas fa-arrow-left" />
      </a>
      <form on:submit|preventDefault={submit}>
        <TextInput
          label="Título"
          class="mb-6"
          rows={4}
          errors={errors.title}
          bind:value={title} />
        <TextAreaInput
          label="Descrição"
          class="mb-6"
          rows={4}
          errors={errors.description}
          bind:value={description} />
        <TextAreaInput
          label="Autores"
          class="mb-6"
          rows={3}
          errors={errors.authors}
          bind:value={authors} />
        <TextInput
          label="Link de compra"
          class="mb-6"
          rows={3}
          errors={errors.affiliateUrl}
          bind:value={affiliateUrl} />
        <TextInput
          label="Total de páginas"
          class="mb-6"
          rows={3}
          errors={errors.pageCount}
          bind:value={pageCount} />
        {#if !fetching}
          <SelectWithLabel
            label="Tópico"
            class="mb-6"
            optional={false}
            options={topicOptions}
            bind:value={topicId} />
        {/if}
        <ImageInput class="mb-6" bind:value={image} label="Imagem" />
        <Button>Salvar</Button>
      </form>
    </div>
  </div>
</div>
