<script>
  import { onMount } from "svelte"
  import { url } from "@sveltech/routify"
  import Input from "atoms/Input.svelte"
  import TableHead from "atoms/TableHead.svelte"
  import ButtonLink from "atoms/ButtonLink.svelte"
  import Pager from "molecules/Pager.svelte"
  import * as API from "apis/backend"
  import hash from "utils/hash"
  import { formatDateTime } from "utils/date"
  import { formatMoney } from "utils/money"
  import { getOrderStatusText, getOrderPaymentMethodText } from "utils/order"

  let page = 0
  let perPage = 25
  let query = ""
  let filters = []
  let sort = "o.createdAt"
  let sortDir = "DESC"

  let total = 0
  let orders = []
  let lastReqHash = null

  function resetPage() {
    page = 0
  }

  $: filters, query, (() => resetPage())()

  $: payload = {
    page,
    perPage,
    filters,
    sort,
    sortDir,
    query,
  }

  $: (async function load() {
    const reqHash = hash(payload)
    if (!lastReqHash || lastReqHash !== reqHash) {
      lastReqHash = reqHash
      const [data, meta] = await API.getOrders(payload)
      if (lastReqHash === reqHash) {
        orders = data
        total = meta.total
      }
    }
  })()
</script>

<div class="flex p-4">
  {#if lastReqHash !== null}
    <div class="flex flex-col flex-grow">
      <div class="flex-grow p-4 ml-4 bg-white rounded-md shadow-md">
        <div class="flex mb-4">
          <Input
            placeholder="Busca por nome, email ou produto."
            debounce={true}
            bind:value={query}
            class="w-full flex-grow-1" />
        </div>
        <table class="w-full table-fixed">
          <thead>
            <tr>
              <TableHead
                class="w-48 px-4 py-2"
                sortKey="o.createdAt"
                bind:sort
                bind:sortDir>
                Data
              </TableHead>
              <TableHead
                class="px-4 py-2"
                sortKey="u.name"
                bind:sort
                bind:sortDir>
                Nome
              </TableHead>
              <TableHead
                class="px-4 py-2"
                sortKey="c.code"
                bind:sort
                bind:sortDir>
                Cupom
              </TableHead>
              <TableHead
                class="px-4 py-2"
                sortKey="o.status"
                bind:sort
                bind:sortDir>
                Status
              </TableHead>
              <th class="px-4 py-2">Produto(s)</th>
              <TableHead
                class="px-4 py-2"
                sortKey="o.paymentMethod"
                bind:sort
                bind:sortDir>
                Método
              </TableHead>
              <TableHead
                class="px-4 py-2"
                sortKey="o.status"
                bind:sort
                bind:sortDir>
                Parcela(s)
              </TableHead>
              <TableHead
                class="px-4 py-2"
                sortKey="o.total"
                bind:sort
                bind:sortDir>
                Total
              </TableHead>
            </tr>
          </thead>
          <tbody>
            {#each orders as order}
              <tr>
                <td class="px-4 py-2 truncate border">
                  {formatDateTime(order.createdAt)}
                </td>
                <td class="px-4 py-2 underline truncate border">
                  <a href={$url(`/usuarios/${order.user.id}`)}>
                    {order.user.name}
                  </a>
                </td>
                <td class="px-4 py-2 truncate border">
                  {#if order.coupon}{order.coupon.code}{/if}
                </td>
                <td class="px-4 py-2 truncate border">
                  {getOrderStatusText(order)}
                </td>
                <td class="px-4 py-2 truncate border">
                  {order.products.map((x) => x.name).join(', ')}
                </td>
                <td class="px-4 py-2 truncate border">
                  {getOrderPaymentMethodText(order)}
                </td>
                <td class="px-4 py-2 text-right truncate border">
                  {order.installments}x {formatMoney(order.amount / order.installments)}
                </td>
                <td class="px-4 py-2 text-right truncate border">
                  {formatMoney(order.amount)}
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
        <Pager {total} bind:page bind:perPage />
      </div>
    </div>
  {/if}
</div>
