<script>
  let className
  export { className as class }
</script>

<svg
  width="432px"
  height="126px"
  viewBox="0 0 432 126"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  class={className}>
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(0.000000, -1.000000)">
      <path
        d="M67.309,123.137 C57.651,125.996 47.823,125.932 38.774,123.454
        C40.456,122.871 42.111,122.243 43.729,121.563 C44.566,121.217
        45.387,120.853 46.199,120.477 C53.286,121.565 60.719,121.143
        68.049,118.971 C94.367,111.178 109.506,83.747 102.228,57.401
        C102.321,57.701 102.419,58.004 102.505,58.303 C110.689,85.928
        94.93,114.953 67.309,123.137"
        fill="#00B0E2" />
      <path
        d="M29.403,76.506 C31.501,74.26 33.796,72.168 36.213,70.195
        C43.542,71.583 50.546,75.798 57.384,81.767 C51.038,74.705 45.716,69.764
        40.336,67.002 C42.724,65.24 46.286,60.686 45.589,60.1 C42.681,61.909
        39.755,63.702 36.911,65.551 C34.837,66.9 32.806,68.271 30.865,69.701
        C27.397,72.247 24.211,74.962 21.538,77.989 C19.402,80.404 17.596,83.018
        16.238,85.905 C15.777,86.886 15.365,87.905 15.013,88.953 C14.886,89.325
        14.77,89.699 14.665,90.072 C14.574,90.372 14.492,90.668 14.419,90.967
        C14.342,91.266 14.274,91.565 14.209,91.863 C14.2,91.905 14.191,91.947
        14.182,91.989 C14.124,92.267 14.07,92.544 14.021,92.822 C14.019,92.834
        14.016,92.846 14.014,92.859 C14.012,92.869 14.011,92.88 14.009,92.89
        C13.983,93.04 13.958,93.192 13.935,93.343 C13.933,93.358 13.931,93.369
        13.929,93.384 C13.924,93.411 13.921,93.44 13.917,93.469 C13.895,93.613
        13.874,93.756 13.855,93.901 C13.839,94.026 13.825,94.152 13.811,94.278
        C13.799,94.384 13.786,94.491 13.776,94.598 L13.775,94.603 C13.759,94.768
        13.744,94.937 13.731,95.105 C13.73,95.119 13.729,95.133 13.728,95.147
        C13.723,95.219 13.719,95.291 13.714,95.363 C13.706,95.484 13.697,95.606
        13.692,95.729 C13.691,95.751 13.691,95.776 13.69,95.797 C13.677,96.081
        13.667,96.365 13.664,96.651 C13.646,98.324 13.811,100.011 14.16,101.745
        C10.37,96.909 7.415,91.293 5.565,85.048 C-2.232,58.731 12.526,31.097
        38.583,22.851 C38.283,22.934 37.978,23.017 37.675,23.107 C10.054,31.288
        -5.706,60.315 2.475,87.937 C5.41,97.845 11.027,106.228 18.302,112.523
        C19.008,113.823 19.792,115.165 20.66,116.547 C20.119,115.548
        19.673,114.485 19.314,113.372 C18.574,111.096 18.191,108.628
        18.061,106.167 C17.994,104.901 17.989,103.639 18.044,102.405
        C18.125,100.465 18.337,98.601 18.624,96.916 C18.78,96.007 18.957,95.155
        19.146,94.368 C20.276,94.018 21.43,93.738 22.619,93.529 C33.664,91.587
        43.896,96.55 53.775,105.178 C41.881,91.948 33.603,86.154 21.764,88.237
        C21.604,88.265 21.445,88.296 21.286,88.327 C22.218,86.298 23.316,84.377
        24.546,82.558 C25.11,82.423 25.677,82.308 26.253,82.204 C37.301,80.259
        47.531,85.225 57.413,93.85 C46.903,82.163 39.217,76.283 29.403,76.506"
        fill="#00B0E2" />
      <path
        d="M97.098,60.662 C97.02,60.398 96.935,60.138 96.854,59.874
        C103.212,82.896 89.983,106.866 66.986,113.68 C61.848,115.201
        56.652,115.74 51.598,115.405 C59.425,110.717 65.487,104.647
        67.956,97.311 C73.358,81.25 61.741,69.473 52.588,58.734 C60.016,53.942
        67.098,49.401 72.02,44.148 C73.318,42.76 74.467,41.327 75.432,39.823
        C76.41,38.299 77.198,36.707 77.763,35.027 C77.818,34.863 79.815,29.868
        78.767,23.462 C78.462,21.599 77.869,19.737 77.068,17.827 C74.925,12.713
        71.12,7.293 65.814,0.837 C69.452,6.552 72.112,12.205 73.343,17.806
        C64.736,18.465 57.419,24.162 47.687,34.607 C56.189,27.442 64.899,22.917
        74.06,23.005 C74.136,24.422 74.11,25.832 73.974,27.242 C73.94,27.602
        73.895,27.961 73.846,28.321 C65.366,27.765 57.314,32.246 46.216,40.926
        C55.235,35.481 64.068,32.405 72.658,33.393 C71.975,35.427 71.079,37.329
        69.999,39.124 C63.414,39.079 55.994,41.711 46.329,46.331 C53.68,43.852
        60.652,42.603 67.083,43.245 C62.839,48.439 57.078,52.709 50.905,56.731
        C45.367,50.027 41.3,43.652 43.607,36.792 C44.896,32.958 48.338,30.089
        51.476,27.551 C46.921,30.283 41.471,33.059 39.816,37.98 C36.198,48.735
        47.256,58.376 52.685,66.386 C58.846,75.479 62.787,85.814 59.12,96.716
        C56.548,104.347 51.479,109.986 44.717,114.394 C43.211,115.376
        41.621,116.3 39.953,117.17 C39.171,117.577 38.376,117.974 37.562,118.362
        C35.654,119.265 33.661,120.111 31.591,120.91 C25.913,123.11
        19.675,124.952 13.128,126.669 C20.406,126.222 28.19,124.8 35.57,122.466
        C38.024,121.69 40.436,120.811 42.768,119.833 C43.753,119.423
        44.721,118.993 45.672,118.547 C52.353,119.702 59.397,119.374
        66.338,117.318 C90.478,110.166 104.249,84.802 97.098,60.662"
        fill="#255171" />
      <path
        d="M125.636,64.456 L127.49,64.456 L127.49,50.618 L125.636,50.618
        L125.636,64.456 Z M138.586,61.819 L131.602,50.618 L129.39,50.618
        L129.39,64.456 L131.178,64.456 L131.178,53.254 L138.266,64.456
        L140.365,64.456 L140.365,50.618 L138.586,50.618 L138.586,61.819 Z
        M150.088,56.878 L147.33,56.199 C146.383,55.968 145.754,55.693
        145.444,55.377 C145.132,55.061 144.977,54.664 144.977,54.186
        C144.977,53.785 145.09,53.404 145.316,53.043 C145.542,52.682
        145.895,52.402 146.375,52.205 C146.855,52.008 147.456,51.908
        148.178,51.908 C149.257,51.908 150.084,52.152 150.658,52.638
        C151.232,53.123 151.557,53.806 151.632,54.685 L153.364,54.685
        C153.339,53.756 153.138,52.972 152.762,52.332 C152.385,51.69
        151.795,51.187 150.992,50.819 C150.189,50.452 149.263,50.269
        148.216,50.269 C147.205,50.269 146.322,50.437 145.565,50.773
        C144.81,51.108 144.233,51.598 143.838,52.241 C143.443,52.885
        143.246,53.598 143.246,54.384 C143.246,55.242 143.504,55.961
        144.022,56.538 C144.54,57.115 145.457,57.57 146.775,57.902
        L149.241,58.514 C150.189,58.753 150.868,59.034 151.279,59.357
        C151.69,59.681 151.896,60.166 151.896,60.811 C151.896,61.232
        151.787,61.608 151.571,61.941 C151.354,62.273 151.021,62.541
        150.568,62.741 C149.953,63.023 149.203,63.165 148.319,63.165
        C147.597,63.165 146.988,63.061 146.493,62.85 C145.997,62.64
        145.603,62.384 145.312,62.082 C145.02,61.781 144.808,61.433
        144.676,61.038 C144.588,60.767 144.535,60.41 144.516,59.965
        L142.784,59.965 C142.778,60.812 142.913,61.536 143.189,62.139
        C143.578,62.992 144.199,63.651 145.052,64.111 C145.906,64.572
        146.969,64.804 148.243,64.804 C149.53,64.804 150.628,64.59
        151.538,64.164 C152.278,63.824 152.835,63.352 153.209,62.746
        C153.582,62.14 153.769,61.438 153.769,60.642 C153.769,59.713
        153.515,58.941 153.006,58.326 C152.498,57.713 151.525,57.229
        150.088,56.878 M153.806,52.238 L158.512,52.238 L158.512,64.456
        L160.367,64.456 L160.367,52.238 L165.064,52.238 L165.064,50.618
        L153.806,50.618 L153.806,52.238 L153.806,52.238 Z M167.069,64.456
        L168.923,64.456 L168.923,50.618 L167.069,50.618 L167.069,64.456 Z
        M170.166,52.238 L174.872,52.238 L174.872,64.456 L176.727,64.456
        L176.727,52.238 L181.423,52.238 L181.423,50.618 L170.166,50.618
        L170.166,52.238 L170.166,52.238 Z M192.228,59.166 C192.228,60.571
        191.926,61.59 191.321,62.22 C190.715,62.851 189.81,63.166 188.605,63.166
        C187.758,63.166 187.058,63.011 186.506,62.7 C185.954,62.389
        185.572,61.983 185.362,61.481 C185.152,60.978 185.047,60.208
        185.047,59.166 L185.047,50.618 L183.193,50.618 L183.193,59.014
        C183.193,60.05 183.268,60.847 183.418,61.406 C183.569,61.958
        183.732,62.382 183.908,62.677 C184.153,63.072 184.474,63.433
        184.873,63.76 C185.271,64.086 185.771,64.341 186.374,64.527
        C186.976,64.712 187.679,64.805 188.482,64.805 C189.731,64.805
        190.787,64.565 191.65,64.089 C192.513,63.611 193.134,62.961
        193.513,62.136 C193.893,61.31 194.083,60.27 194.083,59.015
        L194.083,50.619 L192.228,50.619 L192.228,59.166 Z M206.198,50.618
        L194.94,50.618 L194.94,52.238 L199.646,52.238 L199.646,64.456
        L201.501,64.456 L201.501,52.238 L206.198,52.238 L206.198,50.618
        L206.198,50.618 Z M217.177,52.157 C216.61,51.557 215.915,51.095
        215.093,50.764 C214.271,50.435 213.351,50.27 212.334,50.27
        C211.312,50.27 210.387,50.436 209.558,50.769 C208.73,51.101
        208.014,51.59 207.412,52.233 C206.81,52.877 206.354,53.654
        206.047,54.567 C205.739,55.48 205.586,56.483 205.586,57.574
        C205.586,59.087 205.866,60.39 206.428,61.486 C206.99,62.581
        207.768,63.407 208.763,63.965 C209.757,64.524 210.916,64.804
        212.24,64.804 C213.816,64.804 215.138,64.421 216.208,63.659
        C217.278,62.896 218.048,61.891 218.519,60.642 C218.895,59.651
        219.082,58.586 219.082,57.451 C219.082,56.277 218.881,55.189
        218.48,54.184 C218.18,53.433 217.745,52.757 217.177,52.157
        M216.707,60.407 C216.378,61.267 215.838,61.942 215.088,62.432
        C214.338,62.92 213.42,63.165 212.334,63.165 C211.369,63.165
        210.524,62.968 209.803,62.571 C209.081,62.175 208.51,61.55
        208.089,60.688 C207.669,59.83 207.459,58.796 207.459,57.591
        C207.459,55.923 207.883,54.558 208.73,53.497 C209.577,52.436
        210.778,51.906 212.334,51.906 C213.759,51.906 214.926,52.4
        215.837,53.388 C216.746,54.376 217.201,55.777 217.201,57.59
        C217.201,58.609 217.036,59.548 216.707,60.407 M234.774,56.991
        C235.39,56.716 235.85,56.314 236.158,55.792 C236.466,55.267
        236.62,54.688 236.62,54.056 C236.62,53.433 236.462,52.843 236.144,52.285
        C235.827,51.727 235.364,51.31 234.755,51.034 C234.152,50.758
        233.412,50.619 232.533,50.619 L226.612,50.619 L226.612,64.457
        L232.345,64.457 C233.111,64.457 233.738,64.398 234.227,64.282
        C234.717,64.167 235.117,64.02 235.428,63.844 C235.739,63.668
        236.028,63.43 236.298,63.123 C236.568,62.818 236.798,62.425
        236.989,61.937 C237.18,61.451 237.276,60.93 237.276,60.37
        C237.276,59.431 236.998,58.651 236.439,58.036 C236.051,57.609
        235.496,57.261 234.774,56.991 M228.468,52.238 L231.921,52.238
        C232.625,52.238 233.168,52.305 233.551,52.435 C233.928,52.567
        234.221,52.795 234.431,53.117 C234.641,53.44 234.746,53.842
        234.746,54.318 C234.746,54.701 234.683,55.037 234.558,55.33
        C234.432,55.622 234.286,55.842 234.12,55.988 C233.954,56.136
        233.736,56.251 233.466,56.333 C233.091,56.451 232.575,56.511
        231.922,56.511 L228.469,56.511 L228.469,52.238 L228.468,52.238 Z
        M234.704,62.211 C234.236,62.622 233.385,62.826 232.148,62.826
        L228.468,62.826 L228.468,58.016 L232.148,58.016 C233.278,58.016
        234.103,58.212 234.625,58.605 C235.146,58.998 235.405,59.583
        235.405,60.361 C235.405,61.184 235.171,61.8 234.704,62.211
        M250.767,63.745 C250.666,63.591 250.594,63.407 250.55,63.193
        C250.525,63.037 250.5,62.676 250.475,62.111 L250.409,60.257
        C250.385,59.498 250.236,58.925 249.966,58.535 C249.696,58.145
        249.285,57.85 248.735,57.649 C249.343,57.354 249.82,56.946
        250.165,56.421 C250.511,55.898 250.683,55.195 250.683,54.317
        C250.683,53.546 250.502,52.867 250.138,52.283 C249.774,51.699
        249.269,51.277 248.621,51.013 C247.981,50.748 247.129,50.617
        246.061,50.617 L239.745,50.617 L239.745,64.455 L241.6,64.455
        L241.6,58.515 L245.863,58.515 C246.622,58.515 247.178,58.594
        247.53,58.751 C247.882,58.907 248.135,59.134 248.293,59.43
        C248.449,59.724 248.544,60.173 248.574,60.774 L248.669,62.893
        C248.688,63.3 248.72,63.62 248.763,63.853 C248.787,64.028 248.839,64.229
        248.913,64.456 L251.22,64.456 L251.22,64.144 C251.017,64.031
        250.866,63.898 250.767,63.745 M248.404,56.126 C248.197,56.427
        247.861,56.652 247.397,56.805 C247.077,56.91 246.522,56.963
        245.731,56.963 L241.599,56.963 L241.599,52.238 L246.136,52.238
        C246.989,52.238 247.604,52.379 247.98,52.661 C248.533,53.076
        248.808,53.707 248.808,54.553 C248.808,55.199 248.674,55.724
        248.404,56.126 M256.546,50.618 L251.34,64.456 L253.326,64.456
        L254.813,60.276 L260.208,60.276 L261.694,64.456 L263.68,64.456
        L258.662,50.618 L256.546,50.618 Z M255.333,58.77 L257.506,52.644
        L259.691,58.77 L255.333,58.77 Z M270.965,56.878 L268.208,56.199
        C267.261,55.968 266.631,55.693 266.32,55.377 C266.009,55.061
        265.854,54.664 265.854,54.186 C265.854,53.785 265.967,53.404
        266.193,53.043 C266.42,52.682 266.772,52.402 267.253,52.205
        C267.732,52.008 268.334,51.908 269.055,51.908 C270.135,51.908
        270.961,52.152 271.535,52.638 C272.109,53.123 272.433,53.806
        272.51,54.685 L274.241,54.685 C274.217,53.756 274.015,52.972
        273.638,52.332 C273.262,51.69 272.671,51.187 271.868,50.819
        C271.064,50.452 270.139,50.269 269.092,50.269 C268.081,50.269
        267.198,50.437 266.442,50.773 C265.686,51.108 265.11,51.598
        264.714,52.241 C264.319,52.885 264.122,53.598 264.122,54.384
        C264.122,55.242 264.38,55.961 264.898,56.538 C265.416,57.115
        266.334,57.57 267.651,57.902 L270.117,58.514 C271.064,58.753
        271.744,59.034 272.155,59.357 C272.566,59.681 272.771,60.166
        272.771,60.811 C272.771,61.232 272.663,61.608 272.447,61.941
        C272.23,62.273 271.896,62.541 271.444,62.741 C270.829,63.023
        270.079,63.165 269.195,63.165 C268.473,63.165 267.864,63.061
        267.368,62.85 C266.874,62.64 266.479,62.384 266.187,62.082
        C265.895,61.781 265.683,61.433 265.552,61.038 C265.463,60.767
        265.41,60.41 265.392,59.965 L263.66,59.965 C263.654,60.812
        263.788,61.536 264.065,62.139 C264.454,62.992 265.075,63.651
        265.928,64.111 C266.782,64.572 267.845,64.804 269.118,64.804
        C270.405,64.804 271.503,64.59 272.414,64.164 C273.153,63.824
        273.711,63.352 274.085,62.746 C274.458,62.14 274.644,61.438
        274.644,60.642 C274.644,59.713 274.391,58.941 273.882,58.326
        C273.376,57.713 272.403,57.229 270.965,56.878 M276.009,64.456
        L277.863,64.456 L277.863,50.618 L276.009,50.618 L276.009,64.456 Z
        M281.58,50.618 L279.726,50.618 L279.726,64.456 L288.603,64.456
        L288.603,62.826 L281.581,62.826 L281.581,50.618 L281.58,50.618 Z
        M292.301,58.131 L299.916,58.131 L299.916,56.511 L292.301,56.511
        L292.301,52.239 L300.546,52.239 L300.546,50.619 L290.445,50.619
        L290.445,64.457 L300.677,64.457 L300.677,62.827 L292.3,62.827
        L292.3,58.131 L292.301,58.131 Z M302.305,64.456 L304.159,64.456
        L304.159,50.618 L302.305,50.618 L302.305,64.456 Z M317.316,63.745
        C317.215,63.591 317.143,63.407 317.099,63.193 C317.074,63.037
        317.049,62.676 317.024,62.111 L316.959,60.257 C316.934,59.498
        316.786,58.925 316.517,58.535 C316.246,58.145 315.836,57.85
        315.284,57.649 C315.892,57.354 316.369,56.946 316.715,56.421
        C317.06,55.898 317.233,55.195 317.233,54.317 C317.233,53.546
        317.051,52.867 316.687,52.283 C316.323,51.699 315.818,51.277
        315.172,51.013 C314.531,50.748 313.678,50.617 312.611,50.617
        L306.295,50.617 L306.295,64.455 L308.149,64.455 L308.149,58.515
        L312.413,58.515 C313.173,58.515 313.727,58.594 314.079,58.751
        C314.431,58.907 314.684,59.134 314.842,59.43 C314.998,59.724
        315.092,60.173 315.125,60.774 L315.219,62.893 C315.237,63.3
        315.269,63.62 315.313,63.853 C315.338,64.028 315.388,64.229
        315.463,64.456 L317.77,64.456 L317.77,64.144 C317.567,64.031
        317.417,63.898 317.316,63.745 M314.953,56.126 C314.746,56.427
        314.41,56.652 313.946,56.805 C313.627,56.91 313.071,56.963 312.28,56.963
        L308.148,56.963 L308.148,52.238 L312.685,52.238 C313.538,52.238
        314.153,52.379 314.53,52.661 C315.083,53.076 315.358,53.707
        315.358,54.553 C315.358,55.199 315.223,55.724 314.953,56.126
        M330.509,52.157 C329.94,51.557 329.244,51.095 328.422,50.764
        C327.601,50.435 326.682,50.27 325.664,50.27 C324.642,50.27
        323.717,50.436 322.888,50.769 C322.06,51.101 321.344,51.59
        320.742,52.233 C320.139,52.877 319.684,53.654 319.377,54.567
        C319.07,55.48 318.917,56.483 318.917,57.574 C318.917,59.087
        319.196,60.39 319.759,61.486 C320.32,62.581 321.098,63.407
        322.093,63.965 C323.087,64.524 324.246,64.804 325.571,64.804
        C327.146,64.804 328.468,64.421 329.539,63.659 C330.609,62.896
        331.38,61.891 331.85,60.642 C332.226,59.651 332.413,58.586
        332.413,57.451 C332.413,56.277 332.213,55.189 331.813,54.184
        C331.511,53.433 331.076,52.757 330.509,52.157 M330.037,60.407
        C329.709,61.267 329.169,61.942 328.419,62.432 C327.669,62.92
        326.752,63.165 325.665,63.165 C324.699,63.165 323.855,62.968
        323.134,62.571 C322.412,62.175 321.841,61.55 321.421,60.688
        C321.001,59.83 320.79,58.796 320.79,57.591 C320.79,55.923 321.214,54.558
        322.061,53.497 C322.908,52.436 324.11,51.906 325.665,51.906
        C327.091,51.906 328.257,52.4 329.168,53.388 C330.075,54.376
        330.532,55.777 330.532,57.59 C330.532,58.609 330.367,59.548
        330.037,60.407 M349.607,52.271 C349.02,51.654 348.388,51.223
        347.711,50.981 C347.032,50.74 346.252,50.619 345.366,50.619
        L339.944,50.619 L339.944,64.457 L345.3,64.457 C346.142,64.457
        346.838,64.362 347.391,64.175 C348.187,63.899 348.817,63.537
        349.283,63.092 C349.891,62.494 350.387,61.699 350.77,60.704
        C351.153,59.712 351.343,58.589 351.343,57.341 C351.343,56.256
        351.199,55.294 350.914,54.456 C350.629,53.618 350.194,52.89
        349.607,52.271 M349.169,59.797 C348.968,60.486 348.712,61.042
        348.397,61.462 C348.165,61.776 347.869,62.036 347.508,62.243
        C347.147,62.45 346.78,62.6 346.41,62.695 C346.041,62.782 345.593,62.826
        345.065,62.826 L341.798,62.826 L341.798,52.238 L345.065,52.238
        C345.817,52.238 346.445,52.334 346.946,52.524 C347.449,52.716
        347.886,53.018 348.26,53.427 C348.633,53.838 348.928,54.385
        349.145,55.065 C349.361,55.748 349.470007,56.545 349.470007,57.461
        C349.471,58.327 349.37,59.105 349.169,59.797 M355.72,58.131
        L363.335,58.131 L363.335,56.511 L355.72,56.511 L355.72,52.239
        L363.964,52.239 L363.964,50.619 L353.864,50.619 L353.864,64.457
        L364.097,64.457 L364.097,62.827 L355.719,62.827 L355.719,58.131
        L355.72,58.131 Z M378.893,56.878 L376.136,56.199 C375.188,55.968
        374.559,55.693 374.249,55.377 C373.937,55.061 373.781,54.664
        373.781,54.186 C373.781,53.785 373.895,53.404 374.12,53.043
        C374.347,52.682 374.699,52.402 375.181,52.205 C375.66,52.008
        376.262,51.908 376.983,51.908 C378.062,51.908 378.89,52.152
        379.463,52.638 C380.037,53.123 380.362,53.806 380.437,54.685
        L382.169,54.685 C382.144,53.756 381.943,52.972 381.567,52.332
        C381.189,51.69 380.6,51.187 379.796,50.819 C378.993,50.452
        378.068,50.269 377.021,50.269 C376.011,50.269 375.126,50.437
        374.371,50.773 C373.615,51.108 373.039,51.598 372.643,52.241
        C372.249,52.885 372.05,53.598 372.05,54.384 C372.05,55.242
        372.309,55.961 372.826,56.538 C373.345,57.115 374.263,57.57
        375.579,57.902 L378.046,58.514 C378.993,58.753 379.672,59.034
        380.084,59.357 C380.494,59.681 380.7,60.166 380.7,60.811 C380.7,61.232
        380.593,61.608 380.377,61.941 C380.159,62.273 379.824,62.541
        379.374,62.741 C378.758,63.023 378.009,63.165 377.124,63.165
        C376.401,63.165 375.794,63.061 375.297,62.85 C374.802,62.64
        374.408,62.384 374.115,62.082 C373.824,61.781 373.613,61.433
        373.48,61.038 C373.392,60.767 373.338,60.41 373.32,59.965
        L371.588,59.965 C371.583,60.812 371.718,61.536 371.993,62.139
        C372.383,62.992 373.004,63.651 373.857,64.111 C374.711,64.572
        375.774,64.804 377.048,64.804 C378.334,64.804 379.432,64.59
        380.342,64.164 C381.083,63.824 381.64,63.352 382.012,62.746
        C382.386,62.14 382.573,61.438 382.573,60.642 C382.573,59.713
        382.319,58.941 381.81,58.326 C381.303,57.713 380.33,57.229
        378.893,56.878 M388.09,50.618 L382.886,64.456 L384.871,64.456
        L386.358,60.276 L391.752,60.276 L393.238,64.456 L395.224,64.456
        L390.207,50.618 L388.09,50.618 Z M386.876,58.77 L389.051,52.644
        L391.234,58.77 L386.876,58.77 Z M403.433,46.666 L401.325,46.666
        L399.612,49.441 L400.901,49.441 L403.433,46.666 Z M404.506,59.166
        C404.506,60.571 404.203,61.59 403.598,62.22 C402.992,62.851
        402.088,63.166 400.883,63.166 C400.034,63.166 399.335,63.011
        398.782,62.7 C398.23,62.389 397.849,61.983 397.638,61.481
        C397.428,60.978 397.324,60.208 397.324,59.166 L397.324,50.618
        L395.47,50.618 L395.47,59.014 C395.47,60.05 395.545,60.847
        395.696,61.406 C395.846,61.958 396.01,62.382 396.185,62.677
        C396.43,63.072 396.751,63.433 397.151,63.76 C397.549,64.086
        398.049,64.341 398.652,64.527 C399.255,64.712 399.956,64.805
        400.76,64.805 C402.009,64.805 403.065,64.565 403.928,64.089
        C404.789,63.611 405.41,62.961 405.792,62.136 C406.17,61.31 406.36,60.27
        406.36,59.015 L406.36,50.619 L404.506,50.619 L404.506,59.166
        L404.506,59.166 Z M417.649,52.271 C417.061,51.654 416.43,51.223
        415.753,50.981 C415.074,50.74 414.294,50.619 413.408,50.619
        L407.986,50.619 L407.986,64.457 L413.342,64.457 C414.184,64.457
        414.88,64.362 415.432,64.175 C416.229,63.899 416.859,63.537
        417.324,63.092 C417.933,62.494 418.429,61.699 418.811,60.704
        C419.194,59.712 419.385,58.589 419.385,57.341 C419.385,56.256
        419.241,55.294 418.957,54.456 C418.671,53.617 418.235,52.89
        417.649,52.271 M417.211,59.797 C417.01,60.486 416.753,61.042
        416.439,61.462 C416.208,61.776 415.911,62.036 415.55,62.243
        C415.189,62.45 414.822,62.6 414.452,62.695 C414.083,62.782
        413.635,62.826 413.107,62.826 L409.839,62.826 L409.839,52.238
        L413.107,52.238 C413.859,52.238 414.487,52.334 414.988,52.524
        C415.491,52.716 415.928,53.018 416.301,53.427 C416.675,53.838
        416.97,54.385 417.187,55.065 C417.403,55.748 417.511007,56.545
        417.511007,57.461 C417.512,58.327 417.412,59.105 417.211,59.797
        M422.443,62.826 L422.443,58.131 L430.058,58.131 L430.058,56.511
        L422.443,56.511 L422.443,52.239 L430.688,52.239 L430.688,50.619
        L420.588,50.619 L420.588,64.457 L430.82,64.457 L430.82,62.827
        L422.443,62.827 L422.443,62.826 Z"
        fill="#00B0E2"
        fill-rule="nonzero" />
      <path
        d="M131.26,90.672 L142.256,90.672 L142.256,85.892 L131.26,85.892
        L131.26,79.756 L146.016,79.756 L146.016,74.995 L127.335,74.995
        C126.312,74.995 125.482,75.824 125.482,76.847 L125.482,100.809
        C125.482,101.832 126.312,102.662 127.335,102.662 L146.674,102.662
        L146.674,97.882 L131.26,97.882 L131.26,90.672 Z M167.565,92.573
        C167.565,94.103 167.392,95.245 167.047,95.998 C166.702,96.751
        166.16,97.334 165.419,97.749 C164.679,98.163 163.594,98.37 162.163,98.37
        C160.695,98.37 159.554,98.097 158.738,97.552 C157.922,97.005
        157.396,96.375 157.157,95.66 C156.919,94.958 156.8,93.928 156.8,92.573
        L156.8,74.995 L151.022,74.995 L151.022,91.952 C151.022,93.446
        151.084,94.587 151.21,95.377 C151.436,96.707 151.793,97.812
        152.283,98.689 C152.772,99.568 153.465,100.368 154.362,101.089
        C155.259,101.81 156.379,102.368 157.722,102.763 C159.064,103.159
        160.551,103.357 162.182,103.357 C163.726,103.357 165.121,103.18
        166.369,102.83 C167.618,102.478 168.669,102.015 169.522,101.437
        C170.375,100.861 171.108,100.111 171.724,99.187 C172.339,98.266
        172.784,97.133 173.06,95.791 C173.236,94.914 173.324,93.634
        173.324,91.952 L173.324,74.995 L167.565,74.995 L167.565,92.573
        L167.565,92.573 Z M185.351,80.322 C186.43,79.632 187.678,79.286
        189.096,79.286 C190.776,79.286 192.15,79.764 193.218,80.716
        C194.008,81.431 194.622,82.555 195.062,84.085 L200.84,84.085
        C200.714,82.906 200.47,81.878 200.106,80.999 C199.579,79.718
        198.816,78.577 197.819,77.573 C196.822,76.57 195.567,75.773
        194.055,75.183 C192.543,74.593 190.865,74.298 189.021,74.298
        C186.951,74.298 185.037,74.699 183.281,75.502 C181.9,76.117
        180.721,76.961 179.743,78.033 C178.764,79.108 177.986,80.409
        177.409,81.938 C176.58,84.11 176.166,86.487 176.166,89.072
        C176.166,91.13 176.47,93.093 177.079,94.963 C177.688,96.832
        178.566,98.401 179.714,99.667 C180.862,100.935 182.255,101.867
        183.892,102.462 C185.53,103.058 187.296,103.357 189.19,103.357
        C190.796,103.357 192.333,103.068 193.801,102.491 C195.269,101.914
        196.508,101.101 197.518,100.053 C198.528,99.006 199.297,97.886
        199.824,96.695 C200.187,95.866 200.525,94.694 200.84,93.174
        L195.099,93.174 C194.734,94.443 194.293,95.43 193.773,96.138
        C193.252,96.848 192.565,97.397 191.712,97.786 C190.858,98.174
        189.912,98.369 188.87,98.369 C187.553,98.369 186.37,98.033
        185.322,97.362 C184.275,96.692 183.45,95.618 182.848,94.142
        C182.246,92.668 181.945,90.921 181.945,88.902 C181.945,86.883
        182.242,85.119 182.837,83.614 C183.434,82.11 184.271,81.012
        185.351,80.322 M210.794,74.995 L205.017,74.995 L205.017,100.808
        C205.017,101.831 205.846,102.661 206.869,102.661 L224.552,102.661
        L224.552,97.674 L210.794,97.674 L210.794,74.995 Z M228.317,102.661
        L234.094,102.661 L234.094,74.995 L228.317,74.995 L228.317,102.661 Z
        M250.083,74.995 L237.982,74.995 L237.982,102.661 L248.483,102.661
        C250.778,102.661 252.482,102.551 253.592,102.331 C254.703,102.112
        255.71,101.719 256.613,101.155 C257.516,100.591 258.347,99.724
        259.107,98.557 C259.865,97.39 260.474,95.922 260.932,94.154
        C261.39,92.384 261.62,90.453 261.62,88.359 C261.62,83.928 260.504,80.485
        258.269,78.026 C256.438,76.006 253.709,74.995 250.083,74.995
        M254.958,93.74 C254.656,94.731 254.261,95.509 253.771,96.074
        C253.282,96.637 252.635,97.08 251.833,97.402 C251.029,97.719
        250.019,97.881 248.803,97.881 L243.759,97.881 L243.759,79.756
        L248.803,79.756 C250.132,79.756 251.171,79.904 251.917,80.199
        C252.664,80.494 253.321,81.005 253.893,81.734 C254.464,82.461
        254.91,83.411 255.23,84.584 C255.549,85.758 255.710003,87.033
        255.710003,88.414 C255.711,90.296 255.459,92.072 254.958,93.74
        M271.821,90.672 L282.817,90.672 L282.817,85.892 L271.821,85.892
        L271.821,79.756 L286.576,79.756 L286.576,74.995 L267.895,74.995
        C266.873,74.995 266.043,75.824 266.043,76.847 L266.043,100.809
        C266.043,101.832 266.872,102.662 267.895,102.662 L287.235,102.662
        L287.235,97.882 L271.821,97.882 L271.821,90.672 Z M309.368,88.132
        C308.465,87.63 307.278,87.209 305.81,86.871 L300.127,85.553
        C298.433,85.177 297.397,84.85 297.022,84.575 C296.381,84.111
        296.061,83.433 296.061,82.543 C296.061,81.551 296.466,80.726
        297.276,80.068 C298.086,79.408 299.355,79.079 301.087,79.079
        C302.33,79.079 303.38,79.27 304.239,79.653 C305.098,80.036 305.7,80.518
        306.047,81.101 C306.392,81.684 306.583,82.411 306.62,83.277
        L312.173,83.277 C312.135,82.021 311.993,81.007 311.749,80.236
        C311.505,79.465 311.103,78.712 310.544,77.977 C309.985,77.244
        309.276,76.603 308.418,76.058 C307.558,75.512 306.504,75.082
        305.256,74.768 C304.008,74.456 302.681,74.298 301.276,74.298
        C299.481,74.298 297.769,74.59 296.138,75.173 C294.506,75.756
        293.199,76.697 292.214,77.996 C291.229,79.295 290.736,80.954
        290.736,82.974 C290.736,84.103 290.908,85.105 291.254,85.975
        C291.599,86.847 292.075,87.576 292.684,88.159 C293.293,88.744
        294.18,89.273 295.347,89.751 C296.238,90.101 297.631,90.497
        299.526,90.936 L302.5,91.632 C303.843,91.946 304.846,92.236
        305.511,92.498 C306.176,92.749 306.675,93.087 307.007,93.515
        C307.34,93.942 307.506,94.461 307.506,95.077 C307.506,96.031
        307.124,96.796 306.358,97.373 C305.316,98.176 303.799,98.578
        301.803,98.578 C300.449,98.578 299.3,98.373 298.36,97.966
        C297.419,97.559 296.747,97.003 296.346,96.301 C296.045,95.774
        295.876,95.053 295.838,94.137 L290.285,94.137 C290.348,95.366
        290.502,96.383 290.746,97.186 C290.99,97.989 291.377,98.757
        291.904,99.492 C292.43,100.225 293.087,100.859 293.871,101.391
        C294.654,101.925 295.573,102.349 296.628,102.662 C298.158,103.126
        299.765,103.358 301.445,103.358 C302.776,103.358 304.096,103.223
        305.407,102.953 C306.719,102.682 307.819,102.313 308.71,101.844
        C309.601,101.372 310.348,100.824 310.949,100.197 C311.553,99.57
        312.053,98.763 312.456,97.779 C312.856,96.795 313.058,95.686
        313.058,94.457 C313.058,92.989 312.75,91.741 312.136,90.711
        C311.519,89.681 310.597,88.822 309.368,88.132 M333.721,74.995
        L327.943,74.995 L327.943,102.661 L347.478,102.661 L347.478,97.674
        L333.721,97.674 L333.721,74.995 Z M357.49,90.672 L368.485,90.672
        L368.485,85.892 L357.49,85.892 L357.49,79.756 L372.245,79.756
        L372.245,74.995 L353.564,74.995 C352.543,74.995 351.712,75.824
        351.712,76.847 L351.712,100.809 C351.712,101.832 352.542,102.662
        353.564,102.662 L372.904,102.662 L372.904,97.882 L357.49,97.882
        L357.49,90.672 Z M376.951,102.661 L382.728,102.661 L382.728,74.995
        L376.951,74.995 L376.951,102.661 Z M384.554,79.756 L393.005,79.756
        L393.005,102.661 L398.763,102.661 L398.763,79.756 L407.213,79.756
        L407.213,74.995 L384.554,74.995 L384.554,79.756 Z M416.378,97.881
        L416.378,90.672 L427.373,90.672 L427.373,85.892 L416.378,85.892
        L416.378,79.756 L431.133,79.756 L431.133,74.995 L412.451,74.995
        C411.43,74.995 410.599,75.824 410.599,76.847 L410.599,100.809
        C410.599,101.832 411.429,102.662 412.451,102.662 L431.792,102.662
        L431.792,97.882 L416.378,97.882 L416.378,97.881 Z"
        fill="#255171"
        fill-rule="nonzero" />
    </g>
  </g>
</svg>
