<script>
  import Form from "../_Form.svelte"

  import * as API from "apis/backend"

  export let simulationId
  let simulation
  let fetching

  async function load() {
    fetching = true
    ;[simulation] = await API.getSimulation({ simulationId })
    fetching = false
  }

  $: simulationId, load()
</script>

{#if !fetching}
  <Form {simulation} />
{/if}
