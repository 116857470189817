<script>
  import { createEventDispatcher } from "svelte"

  import ClinicalCaseForm from "./_ClinicalCaseForm.svelte"

  const dispatch = createEventDispatcher()

  export let caseGroupId
  let className = ""
  export { className as class }

  let open = false

  function success({ detail: clinicalCase }) {
    open = false
    dispatch("clinicalCaseCreate", clinicalCase)
  }

  async function toggleOpen() {
    open = !open
  }
</script>

<div class={className}>
  <div class="flex items-center cursor-pointer" on:click={toggleOpen}>
    <div
      class="flex items-center justify-center w-16 h-16 mr-4 text-3xl text-white bg-orange-500 rounded-lg">
      <i class="fas fa-{open ? 'minus' : 'plus'}" />
    </div>
    <div class="w-auto mr-4">Novo Caso Clínico</div>
  </div>
  {#if open}
    <div class="pl-16 mt-4">
      <div class="max-w-md px-4">
        <ClinicalCaseForm {caseGroupId} on:success={success} />
      </div>
    </div>
  {/if}
</div>
