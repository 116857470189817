<script>
  import Form from "../_Form.svelte"

  import * as API from "apis/backend"

  export let couponId
  let coupon
  let fetching

  async function load() {
    fetching = true
    ;[coupon] = await API.getCoupon({ couponId })
    fetching = false
  }

  $: couponId, load()
</script>

{#if !fetching}
  <Form {coupon} />
{/if}
