<script>
  import { createEventDispatcher } from "svelte"

  import * as API from "apis/backend"

  import CaseFrameNewRow from "./_CaseFrameNewRow.svelte"
  import CaseFrameRow from "./_CaseFrameRow.svelte"
  import ClinicalCaseForm from "./_ClinicalCaseForm.svelte"

  let className = ""
  export let index
  export let clinicalCase
  export { className as class }

  let editing = false
  let open = false
  let caseFrames

  const dispatch = createEventDispatcher()

  function caseFrameCreated({ detail: caseFrame }) {
    caseFrames = [...caseFrames, caseFrame]
  }

  function caseFrameUpdated({ detail: caseFrame }) {
    const idx = caseFrames.findIndex((x) => x.id === caseFrame.id)
    caseFrames[idx] = caseFrame
    caseFrames = caseFrames
  }

  function caseFrameDeleted({ detail: caseFrame }) {
    const idx = caseFrames.findIndex((x) => x.id === caseFrame.id)
    caseFrames.splice(idx, 1)
    caseFrames = caseFrames
  }

  async function remove() {
    if (confirm("Tem certeza que quer remover o caso clínico?")) {
      await API.delClinicalCase(clinicalCase.id)
      open = false
      dispatch("clinicalCaseDelete", clinicalCase)
    }
  }

  function success({ detail: clinicalCase }) {
    editing = false
    dispatch("clinicalCaseUpdate", clinicalCase)
  }

  async function toggleOpen() {
    if (!open) {
      const [data, meta] = await API.getCaseFrame(clinicalCase.id)
      caseFrames = data
      open = true
    } else {
      caseFrames = undefined
      open = false
      editing = false
    }
  }

  async function caseFrameReordered({ detail: { from, to } }) {
    const newCaseFrames = [...caseFrames]
    newCaseFrames.splice(to, 0, newCaseFrames.splice(from, 1)[0])
    const caseFrameIds = newCaseFrames.map((x) => x.id)
    await API.postCaseFramesReorder({
      caseId: clinicalCase.id,
      caseFrameIds,
    })
    caseFrames = newCaseFrames
  }
</script>

<div class={className}>
  <div class="flex items-center">
    <div class="relative w-16 h-16 mr-4 overflow-hidden bg-gray-500 rounded-lg">
      <div
        class="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full text-3xl text-white cursor-pointer"
        on:click={toggleOpen}>
        <i class="fas fa-{open ? 'angle-up' : 'angle-down'}" />
      </div>
    </div>
    {clinicalCase.title}
  </div>
  {#if open}
    <div class="pl-16 mt-4">
      <div class="max-w-md px-4">
        {#if !editing}
          <span on:click={() => (editing = true)}><a
              href
              on:click|preventDefault>editar</a></span>
          <span on:click={remove}><a
              href
              on:click|preventDefault>remover</a></span>
        {:else}
          <ClinicalCaseForm {clinicalCase} on:success={success} />
        {/if}
      </div>
    </div>
    {#if !editing}
      <div class="pl-8 mt-4">
        <CaseFrameNewRow
          caseId={clinicalCase.id}
          class="mb-4"
          on:caseFrameCreate={caseFrameCreated} />
        {#each caseFrames as caseFrame, index}
          <CaseFrameRow
            class="mb-4"
            {index}
            {caseFrame}
            on:caseFrameReorder={caseFrameReordered}
            on:caseFrameUpdate={caseFrameUpdated}
            on:caseFrameDelete={caseFrameDeleted} />
        {/each}
      </div>
    {/if}
  {/if}
</div>
