<script>
  import { createEventDispatcher, onMount } from "svelte"

  import Button from "atoms/Button.svelte"
  import TextInput from "molecules/TextInput.svelte"
  import TextAreaInput from "molecules/TextAreaInput.svelte"
  import SelectWithLabel from "molecules/SelectWithLabel.svelte"
  import ImageInput from "atoms/ImageInput.svelte"
  import Checkbox from "atoms/Checkbox.svelte"
  import { mapErrors } from "utils/errors"
  import * as API from "apis/backend"
  import {
    blobFromBase64,
    getFileUrl,
    contentTypeFromBase64,
    isBase64,
  } from "utils/files"

  const dispatch = createEventDispatcher()

  const typeOptions = [
    { key: "courseId", value: "CaseIntro", label: "Introdução" },
    { key: "courseId", value: "TxtFullStatic", label: "Apenas Texto" },
    {
      key: "courseId",
      value: "TxtImgStatic",
      label: "Texto com imagem ao lado",
    },
    { key: "courseId", value: "VideoStatic", label: "Vídeo" },
    { key: "courseId", value: "Question2Answers", label: "Duas Respostas" },
    { key: "courseId", value: "Question3Answers", label: "Três Respostas" },
    { key: "courseId", value: "Question4Answers", label: "Quatro Respostas" },
  ]

  let className = ""
  export { className as class }

  export let caseFrame
  export let caseId
  let id
  let type
  let title
  let content
  let disableBackButton = false
  let videoId
  let image

  let errors = {}

  onMount(async () => {
    if (caseFrame) {
      id = caseFrame.id
      type = caseFrame.type
      title = caseFrame.title
      content = caseFrame.content
      disableBackButton = caseFrame.disableBackButton
      videoId = caseFrame.videoId
      image =
        caseFrame.image &&
        getFileUrl(
          `/clinical-case/${caseFrame.caseId}/case-frame/${caseFrame.id}/image`
        )
    }
  })

  async function submit() {
    const apiFn = id ? API.putCaseFrame : API.postCaseFrame
    const successStatus = id ? 200 : 201
    const imageContentType = contentTypeFromBase64(image)
    const payload = {
      ...(id ? { id } : { caseId }),
      type,
      title,
      content,
      disableBackButton,
      videoId,
      imageContentType,
    }

    const [data, meta] = await apiFn(payload)

    if (meta.status === successStatus) {
      if (isBase64(image) && data.imageUploadUrl)
        await fetch(data.imageUploadUrl, {
          method: "PUT",
          body: blobFromBase64(image),
          headers: { "Content-Type": imageContentType },
        })

      caseFrame = data
      return dispatch("success", caseFrame)
    }

    errors = mapErrors(data)
  }
</script>

<form class={className} on:submit|preventDefault={submit}>
  <TextInput
    label="Título"
    class="mb-6"
    errors={errors.title}
    bind:value={title} />
  <SelectWithLabel
    label="Tipo"
    class="mb-4"
    optional={false}
    options={typeOptions}
    errors={errors.type}
    bind:value={type} />
  {#if type === 'VideoStatic'}
    <TextInput
      label="Vídeo"
      class="mb-6"
      errors={errors.title}
      bind:value={videoId} />
  {/if}
  <ImageInput class="mb-6" bind:value={image} label="Imagem" />
  {#if type !== 'VideoStatic'}
    <TextAreaInput
      label="Conteúdo"
      class="mb-6"
      rows={5}
      errors={errors.content}
      bind:value={content} />
  {/if}
  <Checkbox bind:checked={disableBackButton} class="mb-6">
    Desabilitar botão de voltar
  </Checkbox>
  <Button>Salvar</Button>
</form>
