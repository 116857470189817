<script>
  import jwtDecode from "jwt-decode"
  import { goto } from "@sveltech/routify"
  import Logo from "atoms/Logo.svelte"
  import Button from "atoms/Button.svelte"
  import TextInput from "molecules/TextInput.svelte"
  import { auth } from "stores/Auth"
  import { mapErrors } from "utils/errors"
  import * as API from "apis/backend"

  const errorsMap = {
    email: { shouldBeValid: "Email e/ou senha inválido(s)" },
  }

  let email = ""
  let password = ""
  let errors = {}

  async function submit() {
    errors = {}
    const [data, { status }] = await API.postTokens({ email, password })
    if (status === 200) {
      const user = jwtDecode(data.accessToken)
      if (!user.admin) {
        errors = { email: ["Usuário não é administrador"] }
        return
      }
      auth(data)
      $goto("/")
      return
    }
    errors = mapErrors(data, errorsMap)
  }
</script>

<div class="flex flex-col items-center justify-center h-screen px-4 sm:px-0">
  <form
    class="px-6 py-3 bg-white rounded-md shadow-md sm:w-84 sm:px-8 sm:py-6"
    on:submit|preventDefault={submit}>

    <Logo class="w-full h-auto mb-8" />

    <TextInput
      type="email"
      label="E-mail"
      class="mb-6"
      errors={errors.email}
      bind:value={email} />

    <TextInput
      type="password"
      label="Senha"
      class="mb-8"
      errors={errors.password}
      bind:value={password} />

    <Button class="mb-6">Entrar</Button>
  </form>
</div>
