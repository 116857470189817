<script>
  import * as API from "apis/backend"
  import StatusTick from "atoms/StatusTick.svelte"
  import Button from "atoms/Button.svelte"
  import DetailField from "molecules/DetailField.svelte"
  import { getStatusColor, getStatusText } from "utils/userBundle"
  import { getOrderStatusText, getOrderPaymentMethodText } from "utils/order"
  import { formatDate } from "utils/date"
  import { formatMoney } from "utils/money"

  import UserForm from "./_UserForm.svelte"
  import BundleForm from "./_BundleForm.svelte"
  import OrderForm from "./_OrderForm.svelte"

  export let userId

  let user
  let bundles
  let orders
  let fetching = true
  let editingUser = false
  let editingBundle = false
  let creatingOrder = false

  async function load() {
    fetching = true
    const [userData] = await API.getUser({ userId })
    const [bundlesData] = await API.getUserBundles({ userId })
    const [ordersData] = await API.getOrders({
      sort: "o.createdAt",
      sortDir: "DESC",
      queryObj: { userId },
    })
    user = userData
    bundles = bundlesData
    orders = ordersData
    fetching = false
  }

  async function userSuccess() {
    await load()
    editingUser = false
  }

  async function bundleSuccess() {
    await load()
    editingBundle = false
  }

  async function orderSuccess() {
    await load()
    creatingOrder = false
  }

  async function refund({ id }) {
    if (confirm("Tem certeza que deseja reembolsar essa transação?")) {
      const [data, { status }] = await API.postOrderRefunds({ id })
      if (status === 422) {
        const error =
          {
            refundErrorXX: "Transação já reembolsada",
          }[data.global] || data.global
        alert(`Não foi possível realizar o reembolso: ${error}`)
      }
      await load()
    }
  }

  $: userId, load()
</script>

<div class="flex flex-row p-4">
  {#if !fetching}
    <div class="flex flex-col bg-white rounded-md shadow-md w-96">
      <div
        class="flex items-center justify-center w-full h-24 text-white bg-blue-600 rounded-t-sm">
        <i class="text-4xl fa fa-user" />
      </div>
      <div class="p-4">
        {#if editingUser}
          <UserForm
            {user}
            on:success={userSuccess}
            on:cancel={() => (editingUser = false)} />
        {:else}
          <DetailField label="E-mail" class="mb-6">
            <StatusTick
              class="mr-1"
              color={user.emailConfirmed ? 'green' : 'yellow'} />
            {user.email}
          </DetailField>
          <DetailField label="Nome" class="mb-6">
            <StatusTick
              class="mr-1"
              color={user.nameConfirmed ? 'green' : 'yellow'} />
            {user.name}
          </DetailField>
          <DetailField label="CPF" class="mb-6">
            {user.identity || 'Vazio'}
          </DetailField>
          <DetailField label="Campo" class="mb-6">
            {user.field.name}
          </DetailField>
          <DetailField label="Papel" class="mb-6">
            {user.admin ? 'Administrador' : 'Aluno'}
          </DetailField>
          <Button class="mt-2" on:click={() => (editingUser = true)}>
            Editar
          </Button>
        {/if}
      </div>
    </div>
    <div class="flex flex-col w-64 ml-4 bg-white rounded-md shadow-md">
      <div
        class="flex items-center justify-center w-full h-24 text-white bg-blue-600 rounded-t-sm">
        <i class="text-4xl fa fa-key" />
      </div>
      <div class="p-4">
        {#if editingBundle}
          <BundleForm
            {userId}
            bundle={editingBundle}
            on:success={bundleSuccess}
            on:cancel={() => (editingBundle = false)} />
        {:else}
          {#each bundles as bundle}
            <DetailField label={bundle.name} class="mb-6">
              <div class="flex items-center">
                <StatusTick
                  class="mr-1"
                  color={getStatusColor(bundle.userBundle)} />
                {getStatusText(bundle.userBundle)}
                <i
                  class="block ml-auto cursor-pointer fa fa-pencil"
                  on:click={() => (editingBundle = bundle)} />
              </div>
            </DetailField>
          {/each}
        {/if}
      </div>
    </div>
    <div
      class="flex flex-col flex-grow ml-4 bg-white rounded-md shadow-md w-96">
      <div
        class="flex items-center justify-center w-full h-24 text-white bg-blue-600 rounded-t-sm">
        <i class="text-4xl fa fa-shopping-cart" />
      </div>
      <div class="flex flex-col p-4">
        {#if creatingOrder}
          <OrderForm
            {user}
            on:success={orderSuccess}
            on:cancel={() => (creatingOrder = false)} />
        {:else}
          <div class="mb-4 ml-auto">
            <Button class="px-4" on:click={() => (creatingOrder = true)}>
              Novo Pedido
            </Button>
          </div>

          <table class="w-full table-auto">
            <thead>
              <tr>
                <th class="px-4 py-2">Data</th>
                <th class="px-4 py-2">Cupom</th>
                <th class="px-4 py-2">Status</th>
                <th class="px-4 py-2">Produto(s)</th>
                <th class="px-4 py-2">Método</th>
                <th class="px-4 py-2">Parcela(s)</th>
                <th class="px-4 py-2">Total</th>
                <th class="px-4 py-2">Ações</th>
              </tr>
            </thead>
            <tbody>
              {#each orders as order}
                <tr>
                  <td class="px-4 py-2 text-center truncate border">
                    {formatDate(order.createdAt)}
                  </td>
                  <td class="px-4 py-2 text-center truncate border">
                    {#if order.coupon}{order.coupon.code}{/if}
                  </td>
                  <td class="px-4 py-2 truncate border">
                    {getOrderStatusText(order)}
                  </td>
                  <td class="px-4 py-2 truncate border">
                    {order.products.map((x) => x.name).join(', ')}
                  </td>
                  <td class="px-4 py-2 truncate border">
                    {getOrderPaymentMethodText(order)}
                  </td>
                  <td class="px-4 py-2 text-right truncate border">
                    {order.installments}x {formatMoney(order.amount / order.installments)}
                  </td>
                  <td class="px-4 py-2 text-right truncate border">
                    {formatMoney(order.amount)}
                  </td>
                  <td class="px-4 py-2 text-center truncate border">
                    {#if order.status === 'Complete'}
                      <span
                        class="underline cursor-pointer"
                        on:click={() => refund(order)}>
                        Reembolsar
                      </span>
                    {/if}
                  </td>
                </tr>
              {/each}
            </tbody>
          </table>
        {/if}
      </div>
    </div>
  {/if}
</div>
