<script>
  import * as API from "apis/backend"

  import MaterialForm from "./_MaterialForm.svelte"

  export let topicId
  let materials = []
  let newMaterial = false
  let editMaterial = false

  async function load() {
    ;[materials] = await API.getTopicMaterials({ topicId })
  }
  $: topicId, load()

  function drag(e, idx) {
    e.dataTransfer.setData("idx", idx)
  }

  async function drop(e, idx) {
    const from = e.dataTransfer.getData("idx")
    const to = idx
    const newMaterials = [...materials]
    newMaterials.splice(to, 0, newMaterials.splice(from, 1)[0])
    const materialIds = newMaterials.map(x => x.id)
    await API.postMaterialsReorder({ topicId, materialIds })
    materials = newMaterials
  }

  function createMaterial({ detail: material }) {
    materials = [...materials, material]
    newMaterial = false
  }

  function updateMaterial({ detail: material }) {
    const idx = materials.findIndex(x => x.id === material.id)
    materials[idx] = material
    materials = materials
    editMaterial = false
  }

  async function deleteMaterial(material) {
    if (
      window.confirm(
        `Tem certeza que deseja apagar o material: ${material.title}`
      )
    ) {
      await API.delMaterial(material)
      const idx = materials.findIndex(x => x.id === material.id)
      materials.splice(idx, 1)
      materials = materials
    }
  }
</script>

<style>
  .cursor-grab {
    cursor: grab;
  }
</style>

<div class="flex flex-wrap items-center -mx-2">
  {#if !newMaterial && !editMaterial}
    <div
      class="mx-2 mb-2 border-orange-500 border text-orange-500 bg-white px-4
      h-8 cursor-pointer flex items-center hover:bg-orange-500 rounded-md
      hover:text-white "
      on:click={() => (newMaterial = true)}>
      <i class="fas fa-plus" />
    </div>
    {#each materials as material, idx}
      <div
        class="mx-2 mb-2 h-8 border border-orange-500 flex items-center
        rounded-md overflow-hidden">
        <div
          class="py-1 px-4 flex-grow flex-shrink-0 text-orange-500 bg-white
          hover:text-white hover:bg-orange-500 cursor-grab"
          draggable="true"
          on:dragstart={e => drag(e, idx)}
          on:dragover={e => e.preventDefault()}
          on:drop={e => drop(e, idx)}>
          <i class="fas fa-arrows-alt-h mr-2" />
          {material.title}
        </div>
        <div
          class="flex-grow-0 flex justify-center items-center w-8 px-2 border-l
          h-full text-orange-500 border-orange-500 bg-white hover:text-white
          hover:bg-orange-500 text-sm cursor-pointer"
          on:click={() => (editMaterial = material)}>
          <i class="fas fa-pen" />
        </div>
        <div
          class="flex-grow-0 flex justify-center items-center w-8 border-l
          h-full text-orange-500 border-orange-500 bg-white hover:text-white
          hover:bg-orange-500 cursor-pointer"
          on:click={() => deleteMaterial(material)}>
          <i class="fas fa-times" />
        </div>
      </div>
    {/each}
  {/if}
  {#if newMaterial}
    <MaterialForm
      {topicId}
      on:materialcreated={createMaterial}
      on:cancel={() => (newMaterial = false)} />
  {/if}
  {#if editMaterial}
    <MaterialForm
      {topicId}
      on:materialupdated={updateMaterial}
      material={editMaterial}
      on:cancel={() => (editMaterial = false)} />
  {/if}
</div>
