<script>
  let className = ""
  export let label = ""
  export { className as class }
</script>

<div class={className}>
  <strong class="block mb-1">{label}</strong>
  <div class="truncate">
    <slot />
  </div>
</div>
