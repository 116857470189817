<script>
  export let title
</script>

<style>
  .box {
    @apply flex flex-col
      rounded-md shadow-md
      min-h-full w-full
      bg-white;
  }
  .title {
    @apply flex-shrink-0
      border-b border-gray-300
      p-2;
  }
  .content {
    @apply flex flex-col flex-grow
      items-center justify-center
      p-2 h-full
      text-4xl;
  }
  @screen sm {
    .box {
      @apply w-auto;
    }
  }
</style>

<div class="box">
  <div class="title">{title}</div>
  <div class="content">
    <slot />
  </div>
</div>
