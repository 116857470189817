const statuses = {
  Complete: "Pago",
  Refunded: "Reembolsado",
}

export function getOrderStatusText(order) {
  return statuses[order.status]
}

const paymentMethods = {
  Cielo: "Cielo",
  "Wire Transfer": "Transferência",
}

export function getOrderPaymentMethodText(order) {
  return paymentMethods[order.paymentMethod]
}
