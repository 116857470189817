<script>
  import { onMount } from "svelte"
  import { subYears } from "date-fns"
  import * as R from "ramda"
  import DataBarBox from "molecules/DataBarBox.svelte"
  import * as API from "apis/analytics"
  import { formatDate, FORMAT_DATE_API } from "utils/date"

  const date = formatDate(new Date(), { format: FORMAT_DATE_API })
  const toDate = subYears(date, 5)
  const fromDate = formatDate(new Date(), { format: FORMAT_DATE_API })

  let registrationsReq = API.getRangeRegistrations({ fromDate, toDate })
  let paymentsReq = API.getRangePayments({ fromDate, toDate })

  $: combinedReqs = Promise.all([registrationsReq, paymentsReq])

  let conversionReq = (async () => {
    const [registrations] = await registrationsReq
    const [payments] = await paymentsReq
    return [R.mapObjIndexed((v, k) => (v / registrations[k]) * 100, payments)]
  })()
</script>

<style>
  .wrapper {
    @apply grid w-full grid-cols-1 gap-4 p-4 max-w-6xl;
  }

  @screen sm {
    .wrapper {
      @apply p-8 grid-cols-2;
    }
  }
</style>

<div class="wrapper">
  {#await combinedReqs then [[registrations], [payments]]}
    <DataBarBox
      title="Registros / Pagamentos por Mês"
      groupLabels={['Registros', 'Pagamentos']}
      groups={[registrations, payments]} />
  {/await}
  {#await conversionReq then [conversions]}
    <DataBarBox
      title="Conversão por Mês"
      groupLabels={['Conversão']}
      groups={[conversions]} />
  {/await}
</div>
