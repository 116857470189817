<script>
  import Chart from "chart.js"
  import { onMount } from "svelte"
  import Box from "molecules/Box.svelte"
  import { randomColorFromStr } from "utils/colors"

  export let title
  export let groups = []
  export let labelField = "name"
  export let dataField = "total"
  let canvas

  $: data = groups.map((x) => x[dataField])
  $: backgroundColor = groups.map((x) => randomColorFromStr(x[labelField]))
  $: labels = groups.map((x) => x[labelField])
  $: datasets = [{ backgroundColor, data }]

  onMount(function () {
    new Chart(canvas, {
      type: "pie",
      data: {
        labels,
        datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    })
  })
</script>

<style>
  canvas {
    @apply h-48;
  }
</style>

<Box {title}>
  <canvas bind:this={canvas} />
</Box>
