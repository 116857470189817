<script>
  import Lessons from "./_Lessons.svelte"
  import Materials from "./_Materials.svelte"

  export let topicId
  let activeTab = 0
</script>

<style>
  .item {
    @apply px-2 py-1 mr-2 cursor-pointer;
  }
  .item:hover {
    @apply border-b-2 border-blue-500;
  }
  .item.active {
    @apply border-b-2 border-blue-500;
  }
</style>

<div class="flex mb-4">
  <div
    class="item"
    on:click={() => (activeTab = 0)}
    class:active={activeTab === 0}>
    Módulos
  </div>
  <div
    class="item"
    on:click={() => (activeTab = 1)}
    class:active={activeTab === 1}>
    Materiais
  </div>
</div>

{#if activeTab === 0}
  <Lessons {topicId} />
{:else if activeTab === 1}
  <Materials {topicId} />
{/if}
