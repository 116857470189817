<script>
  import { onMount } from "svelte"
  import Input from "atoms/Input.svelte"
  import StatusTick from "atoms/StatusTick.svelte"
  import CheckboxGroup from "molecules/CheckboxGroup.svelte"
  import Pager from "molecules/Pager.svelte"
  import * as API from "apis/backend"
  import hash from "utils/hash"
  import TopicRow from "./_TopicRow.svelte"
  import NewTopicRow from "./_NewTopicRow.svelte"

  const courseFilterOptions = [
    { key: "courseId", value: 4, label: "1° e 2° período" },
    { key: "courseId", value: 6, label: "3° e 4° período" },
    { key: "courseId", value: 7, label: "Nivelamento" },
    { key: "courseId", value: 5, label: "Workshops" },
  ]

  const imageFilterOptions = [
    {
      key: "image",
      value: false,
      label: "Vazia",
      statusColor: "red",
    },
    {
      key: "image",
      value: true,
      label: "Subida",
      statusColor: "green",
    },
  ]

  let page = 0
  let perPage = 25
  let query = ""
  let filters = [{ key: "courseId", value: 4, label: "1° e 2° período" }]
  let sort = "t.name"
  let sortDir = "ASC"
  let total = 0
  let topics = []
  let lastReqHash = null

  function resetPage() {
    page = 0
  }

  $: filters, query, (() => resetPage())()

  $: payload = {
    page,
    perPage,
    filters,
    sort,
    sortDir,
    query,
  }

  function topicCreated({ detail: topic }) {
    topics = [topic, ...topics]
  }

  function topicUpdated({ detail: topic }) {
    const idx = topics.findIndex((x) => x.id === topic.id)
    topics[idx] = topic
    topics = topics
  }

  function topicDeleted({ detail: topic }) {
    const idx = topics.findIndex((x) => x.id === topic.id)
    topics.splice(idx, 1)
    topics = topics
  }

  $: (async function load() {
    const reqHash = hash(payload)
    if (!lastReqHash || lastReqHash !== reqHash) {
      lastReqHash = reqHash
      const [data, meta] = await API.getTopics(payload)
      if (lastReqHash === reqHash) {
        topics = data
        total = meta.total
      }
    }
  })()
</script>

<div class="flex p-4">
  <div class="flex flex-col flex-shrink-0 w-56 bg-white rounded-md shadow-md">
    <div class="px-6 py-6 border-b border-gray-300">
      <CheckboxGroup
        label="Campo"
        options={courseFilterOptions}
        bind:group={filters} />
    </div>
    <div class="px-6 py-6">
      <CheckboxGroup
        label="Imagem"
        options={imageFilterOptions}
        bind:group={filters} />
    </div>
  </div>
  {#if lastReqHash !== null}
    <div class="flex flex-col flex-grow">
      <div class="flex-grow p-4 ml-4 bg-white rounded-md shadow-md">
        <Input
          placeholder="Busca por tópico, módulo ou assunto."
          debounce={true}
          bind:value={query}
          class="mb-6" />
        <NewTopicRow class="mb-6" on:topicCreate={topicCreated} />
        {#each topics as topic}
          <TopicRow
            class="mb-6"
            on:topicUpdate={topicUpdated}
            on:topicDelete={topicDeleted}
            {topic} />
        {/each}
        <Pager {total} bind:page bind:perPage />
      </div>
    </div>
  {/if}
</div>
