<script>
  let className
  export let errors = false
  export { className as class }
</script>

<style>
  .error {
    @apply text-red-500;
  }
</style>

<label class="block font-bold {className}" class:error={errors}>
  <slot />
</label>
