<script>
  import { createEventDispatcher } from "svelte"

  import { getImageUrl } from "utils/image"
  import * as API from "apis/backend"

  import LessonRow from "./_LessonRow.svelte"
  import NewLessonRow from "./_NewLessonRow.svelte"
  import TopicForm from "./_TopicForm.svelte"

  let className = ""
  export let topic
  export { className as class }

  let editing = false
  let open = false
  let lessons

  const dispatch = createEventDispatcher()

  function lessonCreated({ detail: lesson }) {
    lessons = [...lessons, lesson]
  }

  function lessonUpdated({ detail: lesson }) {
    const idx = lessons.findIndex((x) => x.id === lesson.id)
    lessons[idx] = lesson
    lessons = lessons
  }

  function lessonDeleted({ detail: lesson }) {
    const idx = lessons.findIndex((x) => x.id === lesson.id)
    lessons.splice(idx, 1)
    lessons = lessons
  }

  async function lessonReordered({ detail: { from, to } }) {
    const newLessons = [...lessons]
    newLessons.splice(to, 0, newLessons.splice(from, 1)[0])
    const lessonIds = newLessons.map((x) => x.id)
    await API.postLessonsReorder({ topicId: topic.id, lessonIds })
    lessons = newLessons
  }

  async function remove() {
    if (confirm("Tem certeza que quer remover o tópico?")) {
      await API.delTopic(topic)
      open = false
      dispatch("topicDelete", topic)
    }
  }

  function success({ detail: topic }) {
    editing = false
    dispatch("topicUpdate", topic)
  }

  async function toggleOpen() {
    if (!open) {
      const [data] = await API.getTopicLessons({ topicId: topic.id })
      lessons = data
      open = true
    } else {
      lessons = undefined
      open = false
      editing = false
    }
  }
</script>

<div class={className}>
  <div class="flex items-center cursor-pointer" on:click={toggleOpen}>
    <div
      class="relative w-24 h-24 mr-4 overflow-hidden bg-gray-500 rounded-lg flex-0">
      {#if topic.image}
        <img
          src={`${getImageUrl(`/topics/${topic.id}/image`)}?hash=${new Date(topic.updatedAt).getTime()}`}
          alt={topic.name}
          class="object-cover w-full h-full" />
      {/if}
      <div
        class="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full text-4xl text-white">
        <i class="fas fa-{open ? 'angle-up' : 'angle-down'}" />
      </div>
    </div>
    <div class="w-auto">{topic.name}</div>
  </div>
  {#if open}
    <div class="pl-24 mt-4">
      <div class="max-w-md px-4">
        {#if !editing}
          <span on:click={() => (editing = true)}>editar</span>
          <span on:click={remove}>remover</span>
        {:else}
          <TopicForm {topic} on:success={success} />
        {/if}
      </div>
    </div>
    {#if !editing}
      <div class="pl-8 mt-4">
        <NewLessonRow
          topicId={topic.id}
          class="mb-6"
          on:lessonCreate={lessonCreated} />
        {#each lessons as lesson, index}
          <LessonRow
            class="mb-4"
            {index}
            on:lessonReorder={lessonReordered}
            on:lessonUpdate={lessonUpdated}
            on:lessonDelete={lessonDeleted}
            {lesson} />
        {/each}
      </div>
    {/if}
  {/if}
</div>
