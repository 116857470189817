<script>
  import { url, goto } from "@sveltech/routify"
  import { onMount } from "svelte"
  import Button from "atoms/Button.svelte"
  import TextInput from "molecules/TextInput.svelte"
  import { mapErrors } from "utils/errors"

  import * as API from "apis/backend"

  import { blobFromBase64, contentTypeFromBase64, isBase64 } from "utils/files"
  import ImageInput from "atoms/ImageInput.svelte"

  export let university
  let id
  let name
  let cnpj
  let subdomain
  let integrationSecret
  let integrationKey
  let image
  let errors = {}

  onMount(function () {
    if (university) {
      id = university.id
      name = university.name
      cnpj = university.cnpj
      subdomain = university.subdomain
      integrationSecret = university.integrationSecret
      integrationKey = university.integrationKey
      image = university.logo
    }
  })

  async function submit() {
    const apiFn = id ? API.putUniversity : API.postUniversity
    const successStatus = id ? 200 : 201
    const imageContentType = contentTypeFromBase64(image)
    const payload = {
      ...(id ? { id } : {}),
      name,
      cnpj,
      subdomain,
      integrationSecret,
      integrationKey,
      imageContentType,
    }
    const [data, meta] = await apiFn(payload)

    if (meta.status === successStatus) {
      if (isBase64(image) && data.imageUploadUrl)
        await fetch(data.imageUploadUrl, {
          method: "PUT",
          body: blobFromBase64(image),
          headers: { "Content-Type": imageContentType },
        })
      return $goto("/universidades")
    }

    errors = mapErrors(data)
  }
</script>

<div class="flex p-4">
  <div class="flex flex-col flex-grow">
    <div class="flex-grow p-4 ml-4 bg-white rounded-md shadow-md">
      <a href={$url('/universidades')}>
        <i class="mb-4 text-lg cursor-pointer fas fa-arrow-left" />
      </a>
      <form on:submit|preventDefault={submit}>
        <TextInput
          label="Nome"
          class="mb-6"
          errors={errors.name}
          bind:value={name} />
        <TextInput
          label="CNPJ"
          class="mb-6"
          errors={errors.cnpj}
          bind:value={cnpj} />
        <TextInput
          label="Subdomain"
          class="mb-6"
          errors={errors.subdomain}
          bind:value={subdomain} />
        <TextInput
          label="Secret"
          class="mb-6"
          errors={errors.integrationSecret}
          bind:value={integrationSecret} />
        <TextInput
          label="Key"
          class="mb-6"
          errors={errors.integrationKey}
          bind:value={integrationKey} />
        <ImageInput class="mb-6" bind:value={image} label="Logo" />
        <Button>Salvar</Button>
      </form>
    </div>
  </div>
</div>
