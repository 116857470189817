<script>
  import {goto, url } from '@sveltech/routify'

  import Button from "atoms/Button.svelte"
  import Checkbox from "atoms/Checkbox.svelte"
  import ImageInput from "atoms/ImageInput.svelte"
  import TextAreaInput from "molecules/TextAreaInput.svelte"
  import SelectWithLabel from "molecules/SelectWithLabel.svelte"
  import { mapErrors } from "utils/errors"
  import * as API from "apis/backend"
  import { blobFromBase64, contentTypeFromBase64, isBase64 } from "utils/files"

  import SubjectSelect from "./_SubjectSelect.svelte"

  const difficultyOptions = [
    { value: 1, label: "Muito Fácil" },
    { value: 2, label: "Fácil" },
    { value: 3, label: "Média" },
    { value: 4, label: "Difícil" },
    { value: 5, label: "Muito Difícil" },
  ]

  export let id
  export let subjectId
  export let title
  export let explanation
  export let correctAnswer
  export let wrongAnswer1
  export let wrongAnswer2
  export let wrongAnswer3
  export let wrongAnswer4
  export let wrongAnswer5
  export let difficulty
  export let image
  export let published = false
  let errors = {}

  async function submit() {
    const apiFn = id ? API.putQuestion : API.postQuestion
    const successStatus = id ? 200 : 201
    const imageContentType = contentTypeFromBase64(image)
    const payload = {
      ...(id ? { id } : {}),
      title,
      explanation,
      correctAnswer,
      wrongAnswer1,
      wrongAnswer2,
      wrongAnswer3,
      wrongAnswer4,
      wrongAnswer5,
      difficulty,
      published,
      subjectId,
      imageContentType,
    }
    const [data, meta] = await apiFn(payload)
    if (meta.status === successStatus) {
      if (isBase64(image) && data.imageUploadUrl)
        await fetch(data.imageUploadUrl, {
          method: "PUT",
          body: blobFromBase64(image),
          headers: { "Content-Type": imageContentType },
        })
      return $goto("/perguntas")
    }

    errors = mapErrors(data)
  }
</script>

<div class="flex p-4">
  <div class="flex flex-col flex-grow">
    <div class="flex-grow p-4 ml-4 bg-white rounded-md shadow-md">
      <a href={$url("/perguntas")}>
        <i class="mb-4 text-lg cursor-pointer fas fa-arrow-left" />
      </a>
      <form on:submit|preventDefault={submit}>
        <TextAreaInput
          label="Enunciado"
          class="mb-6"
          rows={4}
          errors={errors.title}
          bind:value={title} />
        <TextAreaInput
          label="Explicação"
          class="mb-6"
          rows={4}
          errors={errors.explanation}
          bind:value={explanation} />
        <TextAreaInput
          label="Resposta Correta"
          class="mb-6"
          rows={3}
          errors={errors.correctAnswer}
          bind:value={correctAnswer} />
        <TextAreaInput
          label="Resposta Incorreta #1"
          class="mb-6"
          rows={3}
          errors={errors.wrongAnswer1}
          bind:value={wrongAnswer1} />
        <TextAreaInput
          label="Resposta Incorreta #2"
          class="mb-6"
          rows={3}
          errors={errors.wrongAnswer2}
          bind:value={wrongAnswer2} />
        <TextAreaInput
          label="Resposta Incorreta #3"
          class="mb-6"
          rows={3}
          errors={errors.wrongAnswer3}
          bind:value={wrongAnswer3} />
        <TextAreaInput
          label="Resposta Incorreta #4"
          class="mb-6"
          rows={3}
          errors={errors.wrongAnswer4}
          bind:value={wrongAnswer4} />
        <SelectWithLabel
          label="Dificuldade"
          class="mb-6"
          bind:value={difficulty}
          options={difficultyOptions}
          optional={false} />
        <Checkbox bind:checked={published} class="mb-6">Publicada</Checkbox>
        <SubjectSelect bind:subjectId />
        <ImageInput class="mb-6" bind:value={image} label="Imagem" />
        <Button>Salvar</Button>
      </form>
    </div>
  </div>
</div>
