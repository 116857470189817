<script>
  import { onMount } from "svelte"
  import { url } from "@sveltech/routify"
  import Input from "atoms/Input.svelte"
  import TableHead from "atoms/TableHead.svelte"
  import ButtonLink from "atoms/ButtonLink.svelte"
  import Pager from "molecules/Pager.svelte"
  import * as API from "apis/backend"
  import { formatDateTime } from "utils/date"
  import hash from "utils/hash"

  let page = 0
  let perPage = 25
  let query = ""
  let filters = []
  let sort = "c.code"
  let sortDir = "DESC"

  let total = 0
  let coupons = []
  let lastReqHash = null

  function resetPage() {
    page = 0
  }

  $: filters, query, (() => resetPage())()

  $: payload = {
    page,
    perPage,
    filters,
    sort,
    sortDir,
    query,
  }

  $: (async function load() {
    const reqHash = hash(payload)
    if (!lastReqHash || lastReqHash !== reqHash) {
      lastReqHash = reqHash
      const [data, meta] = await API.getCoupons(payload)
      if (lastReqHash === reqHash) {
        coupons = data
        total = meta.total
      }
    }
  })()
</script>

<div class="flex p-4">
  {#if lastReqHash !== null}
    <div class="flex flex-col flex-grow">
      <div class="flex-grow p-4 ml-4 bg-white rounded-md shadow-md">
        <div class="flex mb-4">
          <Input
            placeholder="Busca por código."
            debounce={true}
            bind:value={query}
            class="w-full mr-4 flex-grow-1" />
          <ButtonLink class="flex-grow-0 flex-shrink-0" href="/cupons/novo">
            Novo Cupom
          </ButtonLink>
        </div>
        <table class="w-full table-fixed">
          <thead>
            <tr>
              <TableHead
                class="px-4 py-2"
                sortKey="c.code"
                bind:sort
                bind:sortDir>
                Código
              </TableHead>
              <TableHead
                class="px-4 py-2"
                sortKey="c.amount"
                bind:sort
                bind:sortDir>
                Desconto
              </TableHead>
              <TableHead
                class="w-48 px-4 py-2"
                sortKey="ordersCount"
                bind:sort
                bind:sortDir>
                Núm. de Usos
              </TableHead>
              <TableHead
                class="w-48 px-4 py-2"
                sortKey="c.expiresAt"
                bind:sort
                bind:sortDir>
                Expiração
              </TableHead>
              <th class="w-8" />
            </tr>
          </thead>
          <tbody>
            {#each coupons as coupon}
              <tr>
                <td class="px-4 py-2 truncate border">{coupon.code}</td>
                <td class="px-4 py-2 truncate border">{coupon.amount}%</td>
                <td class="px-4 py-2 truncate border">{coupon.ordersCount}</td>
                <td class="px-4 py-2 truncate border">
                  {formatDateTime(coupon.expiresAt)}
                </td>
                <td class="px-2 py-2 text-right border">
                  <a href={$url(`/cupons/${coupon.id}/editar`)}>
                    <i class="cursor-pointer fa fa-pencil" />
                  </a>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
        <Pager {total} bind:page bind:perPage />
      </div>
    </div>
  {/if}
</div>
