import { isPast, differenceInCalendarDays } from "date-fns"
import { formatDate } from "utils/date"

export function getStatusColor(userBundle) {
  if (!userBundle) return "yellow"
  if (userBundle.expiresAt === null) return "green"
  if (isPast(new Date(userBundle.expiresAt))) return "red"
  return "green"
}

export function getStatusText(userBundle) {
  if (!userBundle) return "Trial"
  if (userBundle.expiresAt === null) return "Vitalício"
  const diff = differenceInCalendarDays(
    new Date(userBundle.expiresAt),
    new Date()
  )
  if (isPast(new Date(userBundle.expiresAt)))
    return `Desde ${formatDate(userBundle.expiresAt)}`
  return `Até ${formatDate(userBundle.expiresAt)} (${diff})`
}
