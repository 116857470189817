<script>
  import { createEventDispatcher, onMount } from "svelte"

  import Button from "atoms/Button.svelte"
  import TextInput from "molecules/TextInput.svelte"
  import TextAreaInput from "molecules/TextAreaInput.svelte"
  import Checkbox from "atoms/Checkbox.svelte"
  import { mapErrors } from "utils/errors"
  import * as API from "apis/backend"

  const dispatch = createEventDispatcher()

  let className = ""
  export { className as class }

  export let caseFrameAnswer
  export let caseFramesId
  let id
  let content
  let isCorrect = false
  let explanation

  let errors = {}

  onMount(async () => {
    if (caseFrameAnswer) {
      id = caseFrameAnswer.id
      content = caseFrameAnswer.content
      isCorrect = caseFrameAnswer.isCorrect
      explanation = caseFrameAnswer.explanation
    }
  })

  async function submit() {
    const apiFn = id ? API.putCaseFrameAnswer : API.postCaseFrameAnswer
    const successStatus = id ? 200 : 201
    const payload = {
      ...(id ? { id } : { caseFramesId }),
      content,
      isCorrect,
      explanation,
    }

    const [data, meta] = await apiFn(payload)

    if (meta.status === successStatus) {
      caseFrameAnswer = data
      return dispatch("success", caseFrameAnswer)
    }

    errors = mapErrors(data)
  }
</script>

<form class={className} on:submit|preventDefault={submit}>
  <TextInput
    label="Título"
    class="mb-6"
    errors={errors.content}
    bind:value={content} />
  <TextAreaInput
    label="Explicação"
    class="mb-6"
    rows={5}
    errors={errors.explanation}
    bind:value={explanation} />
  <Checkbox bind:checked={isCorrect} class="mb-6">Resposta correta</Checkbox>
  <Button>Salvar</Button>
</form>
