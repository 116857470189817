export function getFileUrl(path) {
  return `https://s3-sa-east-1.amazonaws.com/${process.env.AWS_S3_BASE_PATH}${path}`
}

export function isBase64(url) {
  return url ? url.indexOf("data:") > -1 : false
}

export function blobFromBase64(base64) {
  const [meta, data] = base64.split(",")
  const type = meta.replace(";base64", "").replace("data:", "")
  let binary = atob(data)
  let array = []
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type })
}

export function contentTypeFromBase64(base64) {
  if (!base64) return null
  const [meta] = base64.split(",")
  return meta.replace(";base64", "").replace("data:", "")
}

export function getMime(mType) {
  const extMap = {
    "image/jpg": "jpg",
    "image/jpeg": "jpeg",
    "application/octet-stream": "glb",
    "image/png": "png",
  }

  return extMap[mType]
}
