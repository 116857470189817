<script>
  import { createEventDispatcher } from "svelte"
  import { getImageUrl } from "utils/image"
  import * as API from "apis/backend"

  import TopicForm from "./_TopicForm.svelte"

  const dispatch = createEventDispatcher()

  let className = ""
  export { className as class }

  let open = false

  function success({ detail: topic }) {
    open = false
    dispatch("topicCreate", topic)
  }

  async function toggleOpen() {
    open = !open
  }
</script>

<div class={className}>
  <div class="flex items-center cursor-pointer" on:click={toggleOpen}>
    <div
      class="flex items-center justify-center w-24 h-24 mr-4 text-3xl text-white bg-orange-500 rounded-lg">
      <i class="fas fa-{open ? 'minus' : 'plus'}" />
    </div>
    <div class="w-auto mr-4">Novo Tópico</div>
  </div>
  {#if open}
    <div class="pl-24 mt-4">
      <div class="max-w-md px-4">
        <TopicForm on:success={success} />
      </div>
    </div>
  {/if}
</div>
