<script>
  import * as API from "apis/backend"

  import LessonRow from "./_LessonRow.svelte"

  export let topicId
  let lessons = []

  async function load() {
    ;[lessons] = await API.getTopicLessons({ topicId })
  }
  $: topicId, load()
</script>

{#each lessons as lesson}
  <LessonRow {lesson} />
{/each}
