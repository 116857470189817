<script>
  let className = ""
  let timeout
  export let value = ""
  export let errors = false
  export let rows = 8
  export { className as class }

  function handleInput(evt) {
    value = evt.target.value
  }
</script>

<style>
  .textarea {
    @apply w-full px-2 text-gray-800 bg-white border border-gray-400 rounded-sm shadow-sm outline-none;
  }
  .textarea:focus {
    @apply border-gray-500;
  }
  .error,
  .error:focus {
    @apply border-red-500;
  }
</style>

<textarea
  {value}
  {rows}
  class={`textarea ${className}`}
  class:error={errors}
  on:input={handleInput} />
