<script>
  import { onMount } from "svelte"

  import SelectWithLabel from "molecules/SelectWithLabel.svelte"
  import * as API from "apis/backend"

  const optionsMapper = ({ id: value, name: label }) => ({
    value,
    label
  })

  let topicId
  let lessonId
  export let subjectId

  let topics
  let topicOptions = []
  let lessons
  let lessonOptions = []
  let subjects
  let subjectOptions = []
  let fetching = true

  async function loadLessons() {
    if (!topicId) return
    const [lessons] = await API.getTopicLessons({ topicId })
    lessonOptions = lessons.map(optionsMapper)
    if (lessons.find(({ id }) => id === lessonId)) return
    if (!lessons[0]) return
    lessonId = lessons[0].id
  }

  async function loadSubjects() {
    if (!lessonId) return
    const [subjects] = await API.getLessonSubjects({ lessonId })
    subjectOptions = subjects.map(optionsMapper)
    if (subjects.find(({ id }) => id === subjectId)) return
    if (!subjects[0]) return
    subjectId = subjects[0].id
  }

  onMount(async function() {
    const [topics] = await API.getTopics({ perPage: 1000 })
    topicOptions = topics.map(optionsMapper)

    if (!!subjectId) {
      const [subject] = await API.getSubject({ id: subjectId })
      lessonId = subject.lessonId
      const [lesson] = await API.getLesson({ id: lessonId })
      topicId = lesson.topicId
    }

    if (!topics[0]) return
    if (!topicId) topicId = topics[0].id

    fetching = false
  })

  $: topicId, loadLessons()
  $: lessonId, loadSubjects()
</script>

{#if !fetching}
  <SelectWithLabel
    label="Tópico"
    class="mb-6"
    optional={false}
    options={topicOptions}
    bind:value={topicId} />
  <SelectWithLabel
    label="Módulo"
    class="mb-6"
    optional={false}
    options={lessonOptions}
    bind:value={lessonId} />
  <SelectWithLabel
    label="Assunto"
    class="mb-6"
    optional={false}
    options={subjectOptions}
    bind:value={subjectId} />
{/if}
