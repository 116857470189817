<script>
  import { onMount } from "svelte"
  import { url } from "@sveltech/routify"
  import Input from "atoms/Input.svelte"
  import StatusTick from "atoms/StatusTick.svelte"
  import CheckboxGroup from "molecules/CheckboxGroup.svelte"
  import TableHead from "atoms/TableHead.svelte"
  import Pager from "molecules/Pager.svelte"
  import * as API from "apis/backend"
  import { formatDateTime } from "utils/date"
  import { getStatusColor, getStatusText } from "utils/userBundle"
  import hash from "utils/hash"
  import { currentUser } from "stores/Auth"

  const emailFilterOptions = [
    {
      key: "emailConfirmed",
      value: false,
      label: "Pendente",
      statusColor: "yellow",
    },
    {
      key: "emailConfirmed",
      value: true,
      label: "Confirmado",
      statusColor: "green",
    },
  ]

  const nameFilterOptions = [
    {
      key: "nameConfirmed",
      value: false,
      label: "Pendente",
      statusColor: "yellow",
    },
    {
      key: "nameConfirmed",
      value: true,
      label: "Confirmado",
      statusColor: "green",
    },
  ]

  const bundleFilterOptions = [
    { key: "bundleNull", value: true, label: "Trial", statusColor: "yellow" },
    { key: "bundleActive", value: true, label: "Ativo", statusColor: "green" },
    {
      key: "bundleExpired",
      value: true,
      label: "Expirado",
      statusColor: "red",
    },
  ]

  const universityOptions = [
    {
      key: "universityExists",
      value: true,
      label: "Unigranrio",
      statusColor: "green",
    },
  ]

  let page = 0
  let perPage = 25
  let query = ""
  let filters = []
  let sort = "u.createdAt"
  let sortDir = "DESC"

  let total = 0
  let users = []
  let lastReqHash = null

  function resetPage() {
    page = 0
  }

  $: filters, query, (() => resetPage())()

  $: payload = {
    page,
    perPage,
    filters,
    sort,
    sortDir,
    query,
  }

  $: (async function load() {
    const reqHash = hash(payload)
    if (!lastReqHash || lastReqHash !== reqHash) {
      lastReqHash = reqHash
      const [data, meta] = await API.getUsers(payload)
      if (lastReqHash === reqHash) {
        users = data
        total = meta.total
      }
    }
  })()

  async function confirmEmail(user) {
    if (
      !user.emailConfirmed &&
      confirm("Tem certeza que deseja confirmar esse e-mail?")
    ) {
      await API.patchUser({ id: user.id, emailConfirmed: true })
      const index = users.findIndex((x) => x.id === user.id)
      users[index].emailConfirmed = true
      users = users
    }
  }

  async function blockDomain(email) {
    const [_, domain] = email.split("@")
    if (confirm("Tem certeza que deseja banir esse domínio?")) {
      const [_, { status }] = await API.postBlacklistedDomain({ domain })
      if (status === 422) {
        alert(`${domain} já está banido`)
      }
    }
  }

  async function deleteUser(user) {
    if (confirm("Tem certeza que deseja remover esse usuário?")) {
      await API.delUser(user)
      const idx = users.findIndex((x) => x.id === user.id)
      users.splice(idx, 1)
      users = users
    }
  }
</script>

<div class="flex p-4">
  <div class="flex flex-col flex-shrink-0 w-56 bg-white rounded-md shadow-md">
    <div class="px-6 py-6 border-b border-gray-300">
      <CheckboxGroup
        label="E-mail"
        options={emailFilterOptions}
        bind:group={filters} />
    </div>
    <div class="px-6 py-6 border-b border-gray-300">
      <CheckboxGroup
        label="Nome"
        options={nameFilterOptions}
        bind:group={filters} />
    </div>
    <div class="px-6 py-6">
      <CheckboxGroup
        label="Ciclo Básico"
        options={bundleFilterOptions}
        bind:group={filters} />
    </div>
    <div class="px-6 py-6">
      <CheckboxGroup
        label="Universidades"
        options={universityOptions}
        bind:group={filters} />
    </div>
  </div>
  {#if lastReqHash !== null}
    <div class="flex flex-col flex-grow">
      <div class="flex-grow p-4 ml-4 bg-white rounded-md shadow-md">
        <Input
          placeholder="Busca por nome, e-mail ou campo."
          debounce={true}
          bind:value={query}
          class="mb-4" />
        <table class="w-full table-fixed">
          <thead>
            <tr>
              <TableHead
                class="px-4 py-2"
                sortKey="u.name"
                bind:sort
                bind:sortDir>
                Nome
              </TableHead>
              <TableHead
                class="px-4 py-2"
                sortKey="u.email"
                bind:sort
                bind:sortDir>
                E-mail
              </TableHead>
              <TableHead
                class="w-48 px-4 py-2"
                sortKey="f.name"
                bind:sort
                bind:sortDir>
                Campo
              </TableHead>
              <TableHead
                class="px-4 py-2"
                sortKey="ub.expiresAt"
                bind:sort
                bind:sortDir>
                Ciclo Básico
              </TableHead>
              <TableHead
                class="px-4 py-2"
                sortKey="uv.name"
                bind:sort
                bind:sortDir>
                Faculdade
              </TableHead>
              <TableHead
                class="w-48 px-4 py-2"
                sortKey="u.createdAt"
                bind:sort
                bind:sortDir>
                Registro
              </TableHead>
              <th class="w-24" />
            </tr>
          </thead>
          <tbody>
            {#each users as user}
              <tr>
                <td class="px-4 py-2 truncate border">
                  <StatusTick
                    class="mr-1"
                    color={user.nameConfirmed ? 'green' : 'yellow'} />
                  {user.name}
                </td>
                <td class="px-4 py-2 truncate border">
                  <StatusTick
                    class="mr-1 {!user.emailConfirmed ? 'cursor-pointer' : ''}"
                    color={user.emailConfirmed ? 'green' : 'yellow'}
                    on:click={() => confirmEmail(user)} />
                  {user.email}
                </td>
                <td class="px-4 py-2 truncate border">{user.field.name}</td>
                <td class="px-4 py-2 truncate border">
                  <StatusTick
                    class="mr-1"
                    color={getStatusColor(user.userBundle)} />
                  {getStatusText(user.userBundle)}
                </td>
                <td class="px-4 py-2 truncate border">
                  {user.universityId ? user.university.name : 'Varejo'}
                </td>
                <td class="px-4 py-2 truncate border">
                  {formatDateTime(user.createdAt)}
                </td>
                <td class="px-2 py-2 text-right border">
                  <span on:click={() => blockDomain(user.email)}>
                    <i class="cursor-pointer fa fa-ban" />
                  </span>
                  {#if $currentUser.email === 'gabriel@ibsel.com.br'}
                    <span on:click={() => deleteUser(user)} class="ml-2">
                      <i class="cursor-pointer fa fa-trash" />
                    </span>
                  {/if}
                  <a
                    href={$url('../:userId', { userId: user.id })}
                    class="ml-2">
                    <i class="cursor-pointer fa fa-pencil" />
                  </a>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
        <Pager {total} bind:page bind:perPage />
      </div>
    </div>
  {/if}
</div>
