<script>
  import { createEventDispatcher, onMount } from "svelte"

  import Button from "atoms/Button.svelte"
  import TextInput from "molecules/TextInput.svelte"
  import SelectWithLabel from "molecules/SelectWithLabel.svelte"
  import { mapErrors } from "utils/errors"
  import * as API from "apis/backend"

  const dispatch = createEventDispatcher()

  let className = ""
  export { className as class }

  export let clinicalCase
  export let caseGroupId
  let id
  let fieldId
  let title = ""
  let errors = {}
  let fieldOptions = []
  let fetchingFields = true

  onMount(async () => {
    if (clinicalCase) {
      id = clinicalCase.id
      title = clinicalCase.title || ""
      fieldId = clinicalCase.fieldId
    }

    const [data] = await API.getFields()
    fieldOptions = data.map(({ id: value, name: label }) => ({ value, label }))

    fetchingFields = false
  })

  async function submit() {
    const apiFn = id ? API.putClinicalCase : API.postClinicalCase
    const successStatus = id ? 200 : 201
    const payload = {
      ...(id ? { id } : { caseGroupId }),
      title,
      fieldId,
    }
    const [data, meta] = await apiFn(payload)
    if (meta.status === successStatus) {
      clinicalCase = data
      return dispatch("success", clinicalCase)
    }

    errors = mapErrors(data)
  }
</script>

<form class={className} on:submit|preventDefault={submit}>
  <TextInput
    label="Nome"
    class="mb-6"
    errors={errors.title}
    bind:value={title} />
  {#if !fetchingFields}
    <SelectWithLabel
      label="Campo"
      class="mb-4"
      optional={false}
      options={fieldOptions}
      errors={errors.fieldId}
      bind:value={fieldId} />
  {/if}
  <Button>Salvar</Button>
</form>
