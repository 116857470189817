<script>
  import Box from "molecules/Box.svelte"

  export let title
  export let current
  export let delta

  $: positive = delta > 0
  $: negative = delta < 0
</script>

<style>
  .delta {
    @apply text-lg;
  }
  .delta.positive {
    @apply text-green-500;
  }
  .delta.negative {
    @apply text-red-500;
  }
</style>

<Box {title}>
  <div>{current}</div>
  {#if delta !== undefined && !isNaN(delta)}
    <div class="delta" class:positive class:negative>{delta}%</div>
  {/if}
</Box>
