<script>
  import { getImageUrl } from "utils/image"
  import { formatRating } from "utils/rating"

  import Tabs from "./_Tabs.svelte"

  export let topic
  let open = false
</script>

<div class="flex flex-col border-b border-gray-300 py-2 mb-2">
  <div class="flex items-center">
    <div class="w-10 h-10 mr-4 rounded-full overflow-hidden bg-gray-500">
      {#if topic.image}
        <img
          src={`${getImageUrl(`/topics/${topic.id}/image`, topic.updatedAt)}`}
          alt={topic.name}
          class="object-cover w-full h-full" />
      {:else}
        <div class="flex justify-center items-center w-full h-full text-white">
          <i class="fas fa-image" />
        </div>
      {/if}
    </div>
    <div class="mr-auto">{topic.name}</div>
    {#if topic.averageRating !== null}
      <div
        class="h-6 px-2 rounded-sm flex justify-center items-center mr-2
        bg-gray-500 text-white"
        class:bg-red-500={topic.averageRating < 3}>
        <i class="fas fa-star mr-1" />
        {formatRating(topic.averageRating)}
      </div>
    {/if}
    <div
      class="h-6 px-2 rounded-sm flex justify-center items-center mr-2
      bg-gray-500 text-white">
      <i class="fas fa-question mr-1" />
      {topic.questionsCount}
    </div>
    <div
      class="w-6 h-6 rounded-sm flex justify-center items-center bg-blue-600
      text-white cursor-pointer"
      on:click={() => (open = !open)}>
      <i class="fas fa-{open ? 'angle-up' : 'angle-down'}" />
    </div>
  </div>
  {#if open}
    <div class="mt-4 ml-12 pl-2">
      <Tabs topicId={topic.id} />
    </div>
  {/if}
</div>
