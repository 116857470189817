<script>
  import Chart from "chart.js"
  import { onMount } from "svelte"
  import Box from "molecules/Box.svelte"
  import { randomColorFromStr } from "utils/colors"

  export let title
  export let groupLabels = [""]
  export let groups = [{}]
  let canvas

  function groupsToColumnLabels(data) {
    return Object.keys(data[0]).sort()
  }

  function groupToDataset(group, index) {
    const label = groupLabels[index]
    const backgroundColor = randomColorFromStr(label)
    const data = columnLabels.map((x) => group[x] || 0)
    return { label, data, backgroundColor }
  }

  $: columnLabels = groupsToColumnLabels(groups)
  $: datasets = groups.map(groupToDataset)

  onMount(function () {
    new Chart(canvas, {
      type: "bar",
      data: {
        labels: columnLabels,
        datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    })
  })
</script>

<style>
  canvas {
    @apply h-64;
  }
</style>

<Box {title}>
  <canvas bind:this={canvas} />
</Box>
