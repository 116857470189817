<script>
  let className
  export let sort
  export let sortDir
  export let sortKey = ""
  export { className as class }

  function handleClick() {
    if (sort === sortKey) {
      sortDir = sortDir === "ASC" ? "DESC" : "ASC"
    } else {
      sort = sortKey
      sortDir = "ASC"
    }
  }
</script>

<th
  class="select-none {sortKey ? 'cursor-pointer' : 'cursor-default'}
  {className}"
  on:click={handleClick}>
  <slot />
  {#if sort === sortKey}
    <i class="ml-1 fa fa-caret-{sortDir === 'ASC' ? 'down' : 'up'}" />
  {/if}
</th>
