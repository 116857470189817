<script>
  import { createEventDispatcher } from "svelte"

  import { getImageUrl } from "utils/image"
  import * as API from "apis/backend"

  import NewSubjectRow from "./_NewSubjectRow.svelte"
  import SubjectRow from "./_SubjectRow.svelte"
  import LessonForm from "./_LessonForm.svelte"

  let className = ""
  export let index
  export let lesson
  export { className as class }

  let editing = false
  let open = false
  let subjects

  const dispatch = createEventDispatcher()

  function subjectCreated({ detail: subject }) {
    subjects = [...subjects, subject]
  }

  function subjectUpdated({ detail: subject }) {
    const idx = subjects.findIndex((x) => x.id === subject.id)
    subjects[idx] = subject
    subjects = subjects
  }

  function subjectDeleted({ detail: subject }) {
    const idx = subjects.findIndex((x) => x.id === subject.id)
    subjects.splice(idx, 1)
    subjects = subjects
  }

  async function remove() {
    if (confirm("Tem certeza que quer remover o módulo?")) {
      await API.delLesson(lesson)
      open = false
      dispatch("lessonDelete", lesson)
    }
  }

  function success({ detail: lesson }) {
    editing = false
    dispatch("lessonUpdate", lesson)
  }

  async function toggleOpen() {
    if (!open) {
      const [data] = await API.getLessonSubjects({ lessonId: lesson.id })
      subjects = data
      open = true
    } else {
      subjects = undefined
      open = false
      editing = false
    }
  }

  function drop(e) {
    e.preventDefault()
    const from = e.dataTransfer.getData("index")
    const to = index
    dispatch("lessonReorder", { from, to })
  }

  function drag(e) {
    e.dataTransfer.setData("index", index)
  }

  async function subjectReordered({ detail: { from, to } }) {
    const newSubjects = [...subjects]
    newSubjects.splice(to, 0, newSubjects.splice(from, 1)[0])
    const subjectIds = newSubjects.map((x) => x.id)
    await API.postSubjectsReorder({ lessonId: lesson.id, subjectIds })
    subjects = newSubjects
  }
</script>

<div class={className}>
  <div class="flex items-center" on:drop={drop}>
    <div class="relative w-16 h-16 mr-4 overflow-hidden bg-gray-500 rounded-lg">
      {#if lesson.image}
        <img
          src={`${getImageUrl(`/topics/${lesson.topicId}/lessons/${lesson.id}/image`)}?hash=${new Date(lesson.updatedAt).getTime()}`}
          alt={lesson.name}
          class="object-cover w-full h-full" />
      {/if}
      <div
        class="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full text-3xl text-white cursor-pointer"
        on:click={toggleOpen}>
        <i class="fas fa-{open ? 'angle-up' : 'angle-down'}" />
      </div>
    </div>
    <div
      draggable="true"
      on:dragstart={drag}
      on:dragover={(e) => e.preventDefault()}>
      <i
        class="px-2 py-1 mr-2 text-white bg-gray-500 rounded-md fas fa-arrows-alt-v" />
      {lesson.name}
    </div>
  </div>
  {#if open}
    <div class="pl-16 mt-4">
      <div class="max-w-md px-4">
        {#if !editing}
          <span on:click={() => (editing = true)}>editar</span>
          <span on:click={remove}>remover</span>
        {:else}
          <LessonForm {lesson} on:success={success} />
        {/if}
      </div>
    </div>
    {#if !editing}
      <div class="pl-8 mt-4">
        <NewSubjectRow
          lessonId={lesson.id}
          class="mb-4"
          on:subjectCreate={subjectCreated} />
        {#each subjects as subject, index}
          <SubjectRow
            class="mb-4"
            {index}
            {subject}
            on:subjectReorder={subjectReordered}
            on:subjectUpdate={subjectUpdated}
            on:subjectDelete={subjectDeleted} />
        {/each}
      </div>
    {/if}
  {/if}
</div>
