<script>
  import { createEventDispatcher } from "svelte"

  import * as API from "apis/backend"
  import hash from "utils/hash"

  import ClinicalCaseRow from "./_ClinicalCaseRow.svelte"
  import NewClinicalCaseRow from "./_ClinicalCaseNewRow.svelte"

  let className = ""
  export let caseGroup
  export { className as class }
  export let payload

  let editing = false
  let open = false
  let clinicalCases = []
  let lastReqHash = null

  const dispatch = createEventDispatcher()

  function clinicalCaseCreated({ detail: clinicalCase }) {
    clinicalCases = [...clinicalCases, clinicalCase]
  }

  function clinicalCaseUpdated({ detail: clinicalCase }) {
    const idx = clinicalCases.findIndex((x) => x.id === clinicalCase.id)
    clinicalCases[idx] = clinicalCase
    clinicalCases = clinicalCases
  }

  function clinicalCaseDeleted({ detail: clinicalCase }) {
    const idx = clinicalCases.findIndex((x) => x.id === clinicalCase.id)
    clinicalCases.splice(idx, 1)
    clinicalCases = clinicalCases
  }

  async function toggleOpen() {
    if (!open) {
      payload.caseGroup = caseGroup.id
      const [data] = await API.getClinicalCases(payload)
      clinicalCases = data
      open = true
    } else {
      clinicalCases = undefined
      open = false
      editing = false
    }
  }
</script>

<div class={className}>
  <div class="flex items-center cursor-pointer" on:click={toggleOpen}>
    <div
      class="relative w-24 h-24 mr-4 overflow-hidden bg-gray-500 rounded-lg flex-0">
      <div
        class="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full text-4xl text-white">
        <i class="fas fa-{open ? 'angle-up' : 'angle-down'}" />
      </div>
    </div>
    <div class="w-auto">{caseGroup.title}</div>
  </div>
  {#if open}
    <div class="pl-8 mt-4">
      <NewClinicalCaseRow
        caseGroupId={caseGroup.id}
        class="mb-6"
        on:clinicalCaseCreate={clinicalCaseCreated} />
      {#each clinicalCases as clinicalCase, index}
        <ClinicalCaseRow
          class="mb-4"
          {index}
          {clinicalCase}
          on:clinicalCaseUpdate={clinicalCaseUpdated}
          on:clinicalCaseDelete={clinicalCaseDeleted} />
      {/each}
    </div>
  {/if}
</div>
