<script>
  let className = "w-full"
  export { className as class }
  export let href
</script>

<a
  {href}
  class={`block rounded-sm text-lg leading-10 bg-blue-700 text-white
  cursor-pointer text-center px-4 ${className}`}>
  <slot />
</a>
