<script>
  import Label from "atoms/Label.svelte"
  import TextArea from "atoms/TextArea.svelte"
  import Errors from "atoms/Errors.svelte"

  let className
  export let label
  export let errors
  export let value = ""
  export let rows
  export let type = "text"
  export { className as class }
</script>

<div class={className}>
  <Label {errors} class="mb-1">{label}</Label>
  <TextArea {type} {rows} {errors} bind:value />
  <Errors {errors} class="mt-1" />
</div>
