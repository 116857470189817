<script>
  import { createEventDispatcher } from "svelte"

  import { getImageUrl } from "utils/image"
  import * as API from "apis/backend"

  import CaseFrameForm from "./_CaseFrameForm.svelte"
  import CaseFrameAnswerRow from "./_CaseFrameAnswerRow.svelte"
  import CaseFrameAnswerNewRow from "./_CaseFrameAnswerNewRow.svelte"

  let className = ""
  export let index
  export let caseFrame
  export { className as class }

  let editing = false
  let open = false
  let caseFrameAnswers

  const dispatch = createEventDispatcher()

  let answerLimit

  switch (caseFrame.type) {
    case "Question2Answers":
      answerLimit = 2
      break
    case "Question3Answers":
      answerLimit = 3
      break
    case "Question4Answers":
      answerLimit = 4
      break
    default:
      answerLimit = 0
  }

  function caseFrameAnswerCreated({ detail: caseFrameAnswer }) {
    caseFrameAnswers = [...caseFrameAnswers, caseFrameAnswer]
  }

  function caseFrameAnswerUpdated({ detail: caseFrameAnswer }) {
    const idx = caseFrameAnswers.findIndex((x) => x.id === caseFrameAnswer.id)
    caseFrameAnswers[idx] = caseFrameAnswer
    caseFrameAnswers = caseFrameAnswers
  }

  function caseFrameAnswerDeleted({ detail: caseFrameAnswer }) {
    const idx = caseFrameAnswers.findIndex((x) => x.id === caseFrameAnswer.id)
    caseFrameAnswers.splice(idx, 1)
    caseFrameAnswers = caseFrameAnswers
  }

  async function remove() {
    if (confirm("Tem certeza que quer remover o módulo?")) {
      await API.delCaseFrame(caseFrame.id)
      open = false
      dispatch("caseFrameDelete", caseFrame.id)
    }
  }

  function success({ detail: lesson }) {
    editing = false
    dispatch("caseFrameUpdate", lesson)
  }

  async function toggleOpen() {
    if (!open) {
      const [data, meta] = await API.getCaseFramesAnswers(caseFrame.id)
      caseFrameAnswers = data
      open = true
    } else {
      caseFrameAnswers = undefined
      open = false
      editing = false
    }
  }

  function drop(e) {
    e.preventDefault()
    const from = e.dataTransfer.getData("index")
    const to = index
    dispatch("caseFrameReorder", { from, to })
  }

  function drag(e) {
    e.dataTransfer.setData("index", index)
  }
</script>

<div class={className}>
  <div class="flex items-center" on:drop={drop}>
    <div class="relative w-16 h-16 mr-4 overflow-hidden bg-gray-500 rounded-lg">
      {#if caseFrame.type === 'TxtImgStatic'}
        {#if caseFrame.image}
          <img
            src={`${getImageUrl(`/clinical-case/${caseFrame.caseId}/case-frame/${caseFrame.id}/image`)}?hash=${new Date(caseFrame.updatedAt).getTime()}`}
            alt={caseFrame.name}
            class="object-cover w-full h-full" />
        {:else}
          <div
            class="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full text-3xl"
            on:click={toggleOpen}>
            <i
              class="px-2 py-1 mr-2 text-white rounded-md fas fa-times text-red-500" />
          </div>
        {/if}
      {:else if caseFrame.type === 'VideoStatic'}
        <div
          class="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full text-3xl">
          <i
            class="px-2 py-1 mr-2 text-white rounded-md
                 fas fa-{caseFrame.videoId ? 'video' : 'video-slash'}
                 text-{caseFrame.videoId ? 'green' : 'red'}-500" />
        </div>
      {:else if caseFrame.type === 'TxtFullStatic'}
        <div
          class="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full text-3xl text-white">
          <i class="fas fa-angle-right" />
        </div>
      {:else if caseFrame.type === 'CaseIntro'}
      <div
        class="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full text-3xl text-white">
        <i class="fas fa-angle-right" />
      </div>        
      {:else}
        <div
          class="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full text-3xl text-white cursor-pointer"
          on:click={toggleOpen}>
          <i class="fas fa-{open ? 'angle-up' : 'angle-down'}" />
        </div>
      {/if}
    </div>
    <div
      draggable="true"
      on:dragstart={drag}
      on:dragover={(e) => e.preventDefault()}>
      <i
        class="px-2 py-1 mr-2 text-white bg-gray-500 rounded-md fas fa-arrows-alt-v" />
      {caseFrame.type === 'VideoStatic' ? caseFrame.videoId : caseFrame.title}
      {#if caseFrame.type === 'TxtFullStatic' || caseFrame.type === 'CaseIntro' || caseFrame.type === 'VideoStatic' || caseFrame.type === 'TxtImgStatic'}
        {#if !editing}
          |
          <span on:click={() => (editing = true)}>
            <a href on:click|preventDefault>editar</a></span>
          <span on:click={remove}><a
              href
              on:click|preventDefault>remover</a></span>
        {:else}
          <div class="pl-16 mt-4">
            <div class="max-w-md px-4">
              <CaseFrameForm {caseFrame} on:success={success} />
            </div>
          </div>
          <span
            class="ml-2 cursor-pointer"
            on:click|preventDefault={() => (editing = false)}>
            <a href>cancelar</a>
          </span>
        {/if}
      {/if}
    </div>
  </div>
  {#if open}
    <div class="pl-16 mt-4">
      <div class="max-w-md px-4">
        {#if !editing}
          <span on:click={() => (editing = true)}>
            <a href on:click|preventDefault>editar</a></span>
          <span on:click={remove}><a
              href
              on:click|preventDefault>remover</a></span>
        {:else}
          <CaseFrameForm {caseFrame} on:success={success} />
        {/if}
      </div>
    </div>
    {#if !editing}
      <div class="pl-8 mt-4">
        {#if answerLimit > caseFrameAnswers.length}
          <CaseFrameAnswerNewRow
            caseFramesId={caseFrame.id}
            class="mb-4"
            on:caseFrameAnswerCreate={caseFrameAnswerCreated} />
        {/if}
        {#each caseFrameAnswers as caseFrameAnswer, index}
          <CaseFrameAnswerRow
            class="mb-4"
            {caseFrameAnswer}
            on:CaseFrameAnswerUpdate={caseFrameAnswerUpdated}
            on:caseFrameAnswerDelete={caseFrameAnswerDeleted} />
        {/each}
      </div>
    {/if}
  {/if}
</div>
