<script>
  import { createEventDispatcher } from "svelte"
  import { onMount } from "svelte"
  import { mapErrors } from "utils/errors"
  import * as API from "apis/backend"
  import Button from "atoms/Button.svelte"
  import SelectWithLabel from "molecules/SelectWithLabel.svelte"
  import TextInput from "molecules/TextInput.svelte"

  const dispatch = createEventDispatcher()

  export let user
  let userId
  let identity
  let productId
  let couponCode
  let productOptions = []
  let errors = {}
  let fetchingProducts = true

  onMount(async () => {
    userId = user.id
    identity = user.identity
    const [data] = await API.getProducts()
    productOptions = data.map(({ id: value, name: label }) => ({
      value,
      label
    }))
    fetchingProducts = false
  })

  function cancel(e) {
    e.preventDefault()
    dispatch("cancel")
  }

  async function submit() {
    errors = {}
    const [data, { status }] = await API.postOrder({
      userId,
      identity,
      productIds: [productId],
      couponCode,
    })
    if (status === 204) {
      return dispatch("success")
    }
    errors = mapErrors(data)
  }
</script>

<form on:submit|preventDefault={submit}>
  <TextInput
    label="CPF"
    class="mb-4"
    errors={errors.identity}
    bind:value={identity} />
  {#if !fetchingProducts}
    <SelectWithLabel
      label="Produto"
      class="mb-4"
      optional={false}
      options={productOptions}
      errors={errors.productIds}
      bind:value={productId} />
  {/if}
  <TextInput
    label="Cupom"
    class="mb-4"
    errors={errors.couponCode}
    bind:value={couponCode} />
  <div class="grid grid-cols-2 gap-4">
    <Button>Salvar</Button>
    <Button on:click={cancel}>Cancelar</Button>
  </div>
</form>
