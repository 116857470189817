<script>
  import { createEventDispatcher } from "svelte"

  import { getImageUrl } from "utils/image"
  import * as API from "apis/backend"

  import SubjectForm from "./_SubjectForm.svelte"

  let className = ""
  export let subject
  export let index
  export { className as class }
  let editing = false

  const dispatch = createEventDispatcher()

  async function remove() {
    if (confirm("Tem certeza que quer remover o assunto?")) {
      await API.delSubject(subject)
      open = false
      dispatch("subjectDelete", subject)
    }
  }

  function success({ detail: subject }) {
    editing = false
    dispatch("subjectUpdate", subject)
  }

  function drop(e) {
    e.preventDefault()
    const from = e.dataTransfer.getData("index")
    const to = index
    dispatch("subjectReorder", { from, to })
  }

  function drag(e) {
    e.dataTransfer.setData("index", index)
  }

  function copy(e, subject) {
    const el = e.target.parentNode.querySelector("input")
    el.value = subject.id
    el.select()
    document.execCommand("copy")
  }
</script>

<div class={className}>
  <div class="flex items-center" on:drop={drop}>
    <div
      class="flex items-center justify-center w-8 h-8 mr-4 text-white bg-{subject.vimeoId ? 'blue' : 'red'}-500
      rounded-lg">
      <i
        class="block cursor-pointer fas fa-{subject.vimeoId ? 'video' : 'video-slash'}" />
    </div>
    <div
      draggable="true"
      on:dragstart={drag}
      on:dragover={(e) => e.preventDefault()}>
      <i
        class="px-2 py-1 mr-2 text-white bg-gray-500 rounded-md fas fa-arrows-alt-v" />
    </div>
    <div
      class="relative flex items-center justify-center w-6 h-6 mr-2 text-white bg-gray-500 rounded-md cursor-pointer"
      on:click={(e) => copy(e, subject)}>
      <i class="pointer-events-none fa fa-copy" />
      <input
        class="absolute top-0 left-0 w-full h-full opacity-0 pointer-events-none"
        value={subject.id} />
    </div>
    {subject.name}
    {#if !editing}
      <span class="ml-2 cursor-pointer" on:click={() => (editing = true)}>
        editar
      </span>
      <span class="ml-1 cursor-pointer" on:click={remove}>remover</span>
    {:else}
      <span class="ml-2 cursor-pointer" on:click={() => (editing = false)}>
        cancelar
      </span>
    {/if}
  </div>
  {#if editing}
    <div class="max-w-md pl-12 mt-4">
      <SubjectForm {subject} on:success={success} />
    </div>
  {/if}
</div>
