<script>
  import Checkbox from "atoms/Checkbox.svelte"
  import StatusTick from "atoms/StatusTick.svelte"

  let className = ""
  export let group = {}
  export let label = ""
  export let options = []
  export { className as class }
</script>

<div class={className}>
  <h3 class="mb-4 font-bold">{label}</h3>
  {#each options as option, i}
    <Checkbox
      bind:group
      key={option.key}
      value={option.value}
      class={i < options.length - 1 ? 'mb-2' : ''}>
      {#if option.statusColor}
        <StatusTick class="mr-1" color={option.statusColor} />
      {/if}
      {option.label}
    </Checkbox>
  {/each}
</div>
