<script>
  import Form from "../_Form.svelte"

  import * as API from "apis/backend"
  import { getImageUrl } from "utils/image"

  export let bookId
  let book
  let fetching

  async function load() {
    fetching = true
    ;[book] = await API.getBook({ bookId })
    const { id, image } = book
    if (image) book.image = getImageUrl(`/books/${id}/image`)
    fetching = false
  }

  $: bookId, load()
</script>

{#if !fetching}
  <Form {...book} />
{/if}
