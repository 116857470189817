const defaultErrorMap = {
  shouldBePresent: "Campo obrigatório.",
  shouldBeValid: "Valor inválido.",
}
export function mapErrors(data, errorsMap = {}) {
  return Object.entries(data).reduce((rs, [field, errors]) => {
    const fieldErrors = errorsMap[field] || {}
    const values = errors
      .map((error) => fieldErrors[error] || defaultErrorMap[error])
      .filter((x) => !!x)
    return {
      ...rs,
      ...(values.length > 0 ? { [field]: values } : {}),
    }
  }, {})
}
