<script>
  import { createEventDispatcher, onMount } from "svelte"

  import Button from "atoms/Button.svelte"
  import TextInput from "molecules/TextInput.svelte"
  import TextAreaInput from "molecules/TextAreaInput.svelte"
  import { mapErrors } from "utils/errors"
  import * as API from "apis/backend"

  const dispatch = createEventDispatcher()

  let className = ""
  export { className as class }

  export let subject
  export let lessonId
  let id
  let name
  let vimeoId
  let transcript
  let errors = {}

  onMount(function () {
    if (subject) {
      id = subject.id
      name = subject.name
      vimeoId = subject.vimeoId
      transcript = subject.transcript
    }
  })

  async function submit() {
    const apiFn = id ? API.putSubject : API.postSubject
    const successStatus = id ? 200 : 201
    const payload = {
      ...(id ? { id } : { lessonId }),
      name,
      vimeoId,
      transcript,
    }
    const [data, meta] = await apiFn(payload)
    if (meta.status === successStatus) {
      subject = data
      return dispatch("success", subject)
    }

    errors = mapErrors(data)
  }
</script>

<form class={className} on:submit|preventDefault={submit}>
  <TextInput label="Nome" class="mb-6" errors={errors.name} bind:value={name} />
  <TextInput
    label="Vimeo ID"
    class="mb-6"
    errors={errors.vimeoId}
    bind:value={vimeoId} />
  <TextAreaInput
    label="Transcrição"
    class="mb-6"
    errors={errors.transcript}
    bind:value={transcript} />
  <Button>Salvar</Button>
</form>
