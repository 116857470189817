<script>
  import { createEventDispatcher, onMount } from "svelte"
  import Button from "atoms/Button.svelte"
  import TextInput from "molecules/TextInput.svelte"
  import ImageInput from "atoms/ImageInput.svelte"
  import FileInput from "atoms/FileInput.svelte"
  import { mapErrors } from "utils/errors"
  import {
    getFileUrl,
    blobFromBase64,
    contentTypeFromBase64,
    isBase64
  } from "utils/files"

  import * as API from "apis/backend"

  const dispatch = createEventDispatcher()

  export let material
  export let topicId
  let id
  let title
  let file
  let errors = {}

  onMount(function() {
    if (material) {
      id = material.id
      title = material.title
      file =
        material.file &&
        getFileUrl(`/topics/${material.topicId}/materials/${material.id}/file`)
    }
  })

  function cancel() {
    dispatch("cancel")
  }

  async function submit() {
    const apiFn = id ? API.putMaterial : API.postMaterial
    const successStatus = id ? 200 : 201
    const successEvent = `material${id ? "updated" : "created"}`
    const fileContentType = contentTypeFromBase64(file)
    const payload = {
      ...(id ? { id } : { topicId }),
      title,
      fileContentType
    }
    const [data, meta] = await apiFn(payload)
    if (meta.status === successStatus) {
      if (isBase64(file) && data.fileUploadUrl)
        await fetch(data.fileUploadUrl, {
          method: "PUT",
          body: blobFromBase64(file),
          headers: { "Content-Type": fileContentType }
        })
      material = data
      return dispatch(successEvent, material)
    }

    errors = mapErrors(data)
  }
</script>

<form class="mb-2" on:submit|preventDefault={submit}>
  <i class="fas fa-arrow-left mb-4 text-lg cursor-pointer" on:click={cancel} />
  <TextInput
    label="Título"
    class="mb-6"
    errors={errors.title}
    bind:value={title} />
  <FileInput class="mb-6" bind:value={file} label="Arquivo" />
  <Button>Salvar</Button>
</form>
