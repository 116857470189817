<script>
  let className = ""
  let timeout
  export let value = ""
  export let placeholder = ""
  export let type = "text"
  export let errors = false
  export let debounce = false
  export { className as class }

  function handleInput(evt) {
    if (debounce) {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        value = evt.target.value
      }, 750)
    } else {
      value = evt.target.value
    }
  }
</script>

<style>
  .input {
    @apply w-full h-10 px-2 text-gray-800 bg-white border border-gray-400 rounded-sm shadow-sm outline-none;
  }
  .input:focus {
    @apply border-gray-500;
  }
  .error,
  .error:focus {
    @apply border-red-500;
  }
</style>

<input
  {type}
  {value}
  {placeholder}
  class={`input ${className}`}
  class:error={errors}
  on:input={handleInput} />
