<script>
  import { createEventDispatcher } from "svelte"
  import { onMount } from "svelte"
  import { mapErrors } from "utils/errors"
  import * as API from "apis/backend"
  import Button from "atoms/Button.svelte"
  import SelectWithLabel from "molecules/SelectWithLabel.svelte"
  import TextInput from "molecules/TextInput.svelte"

  const dispatch = createEventDispatcher()

  export let user
  let id
  let email
  let name
  let admin
  let identity
  let fieldId
  let fieldOptions = []
  let errors = {}
  let fetchingFields = true

  const roleOptions = [
    { value: false, label: "Aluno" },
    { value: true, label: "Administrador" }
  ]

  onMount(async () => {
    id = user.id
    email = user.email
    name = user.name
    identity = user.identity
    fieldId = user.fieldId
    admin = user.admin

    const [data] = await API.getFields()
    fieldOptions = data.map(({ id: value, name: label }) => ({ value, label }))

    fetchingFields = false
  })

  function cancel(e) {
    e.preventDefault()
    dispatch("cancel")
  }

  async function submit() {
    errors = {}
    const [data, { status }] = await API.patchUser({
      id,
      name,
      email,
      admin,
      identity,
      fieldId
    })
    if (status === 200) {
      return dispatch("success")
    }
    errors = mapErrors(data)
  }
</script>

<form on:submit|preventDefault={submit}>
  <TextInput
    type="email"
    label="E-mail"
    class="mb-4"
    errors={errors.email}
    bind:value={email} />
  <TextInput label="Nome" class="mb-4" errors={errors.name} bind:value={name} />
  <TextInput
    label="CPF"
    class="mb-4"
    errors={errors.identity}
    bind:value={identity} />
  {#if !fetchingFields}
    <SelectWithLabel
      label="Campo"
      class="mb-4"
      optional={false}
      options={fieldOptions}
      errors={errors.fieldId}
      bind:value={fieldId} />
  {/if}
  <SelectWithLabel
    label="Papel"
    class="mb-6"
    optional={false}
    options={roleOptions}
    errors={errors.admin}
    bind:value={admin} />
  <div class="grid grid-cols-2 gap-4">
    <Button>Salvar</Button>
    <Button on:click={cancel}>Cancelar</Button>
  </div>
</form>
