import { get, post, put, patch, del } from "../utils/api"

export async function postTokens(body) {
  return await post(`/public/tokens`, { body, skipJWT: true })
}

export async function getUsers({
  perPage = 20,
  page = 0,
  filters = [],
  sort,
  sortDir,
  query = "",
} = {}) {
  return await get(`/admin/users`, {
    queryObj: { perPage, page, sort, sortDir, query },
    query: filters,
  })
}

export async function getOrders({
  perPage = 20,
  page = 0,
  filters = [],
  sort,
  sortDir,
  query = "",
  queryObj = {},
} = {}) {
  return await get(`/admin/orders`, {
    queryObj: { perPage, page, sort, sortDir, query, ...queryObj },
    query: filters,
  })
}

export async function getTopics({
  perPage = 20,
  page = 0,
  filters = [],
  sort = "t.name",
  sortDir = "ASC",
  query = "",
  queryObj = {},
} = {}) {
  return await get(`/admin/topics`, {
    queryObj: { perPage, page, sort, sortDir, query, ...queryObj },
    query: filters,
  })
}

export async function postTopic(body) {
  return await post(`/admin/topics`, { body })
}

export async function putTopic({ id, ...body }) {
  return await put(`/admin/topics/${id}`, { body })
}

export async function delTopic({ id }) {
  return await del(`/admin/topics/${id}`)
}

export async function getTopicMaterials({ topicId }) {
  return await get(`/admin/materials`, {
    queryObj: { topicId },
  })
}

export async function postMaterial(body) {
  return await post(`/admin/materials`, { body })
}

export async function postMaterialsReorder({ topicId, materialIds }) {
  return await post(`/admin/materials-reorder`, {
    queryObj: { topicId },
    body: { materialIds },
  })
}

export async function putMaterial({ id, ...body }) {
  return await put(`/admin/materials/${id}`, { body })
}

export async function delMaterial({ id }) {
  return await del(`/admin/materials/${id}`)
}

export async function getTopicLessons({ topicId }) {
  return await get(`/admin/lessons`, {
    queryObj: { topicId },
  })
}

export async function postLesson(body) {
  return await post(`/admin/lessons`, { body })
}

export async function postLessonsReorder({ topicId, lessonIds }) {
  return await post(`/admin/lessons-reorder`, {
    queryObj: { topicId },
    body: { lessonIds },
  })
}

export async function putLesson({ id, ...body }) {
  return await put(`/admin/lessons/${id}`, { body })
}

export async function delLesson({ id }) {
  return await del(`/admin/lessons/${id}`)
}

export async function getLessonSubjects({ lessonId }) {
  return await get(`/admin/subjects`, {
    queryObj: { lessonId },
  })
}

export async function postSubject(body) {
  return await post(`/admin/subjects`, { body })
}

export async function postSubjectsReorder({ lessonId, subjectIds }) {
  return await post(`/admin/subjects-reorder`, {
    queryObj: { lessonId },
    body: { subjectIds },
  })
}

export async function putSubject({ id, ...body }) {
  return await put(`/admin/subjects/${id}`, { body })
}

export async function delSubject({ id }) {
  return await del(`/admin/subjects/${id}`)
}

export async function getFields() {
  return await get(`/admin/fields`)
}

export async function getProducts() {
  return await get(`/admin/products`)
}

export async function getUser({ userId }) {
  return await get(`/admin/users/${userId}`)
}

export async function getUserBundles({ userId }) {
  return await get(`/admin/users/${userId}/bundles`)
}

export async function patchUserBundle({ userId, bundleId, expiresAt }) {
  const body = { expiresAt }
  return await patch(`/admin/users/${userId}/bundles/${bundleId}`, { body })
}

export async function patchUser(body) {
  return await patch(`/admin/users/${body.id}`, { body })
}

export async function postOrderRefunds({ id }) {
  return await post(`/admin/orders/${id}/refunds`)
}

export async function postOrder(body) {
  return await post(`/admin/orders`, { body })
}

export async function getCoupons({
  perPage = 20,
  page = 0,
  filters = [],
  sort,
  sortDir,
  query = "",
} = {}) {
  return await get(`/admin/coupons`, {
    queryObj: { perPage, page, sort, sortDir, query },
    query: filters,
  })
}

export async function getCoupon({ couponId }) {
  return await get(`/admin/coupons/${couponId}`)
}

export async function postCoupon(body) {
  return await post(`/admin/coupons`, { body })
}

export async function putCoupon({ id, ...body }) {
  return await put(`/admin/coupons/${id}`, { body })
}

export async function getUniversities({
  perPage = 20,
  page = 0,
  filters = [],
  sort,
  sortDir,
  query = "",
} = {}) {
  return await get(`/admin/universities`, {
    queryObj: { perPage, page, sort, sortDir, query },
    query: filters,
  })
}

export async function getUniversity({ universityId }) {
  return await get(`/admin/universities/${universityId}`)
}

export async function postUniversity(body) {
  return await post(`/admin/universities`, { body })
}

export async function putUniversity({ id, ...body }) {
  return await put(`/admin/universities/${id}`, { body })
}

export async function delUniversity({ id }) {
  return await del(`/admin/universities/${id}`)
}

export async function getQuestions({
  perPage = 20,
  page = 0,
  filters = [],
  sort,
  sortDir,
  query = "",
} = {}) {
  return await get(`/admin/questions`, {
    queryObj: { perPage, page, sort, sortDir, query },
    query: filters,
  })
}

export async function getQuestion({ questionId }) {
  return await get(`/admin/questions/${questionId}`)
}

export async function postQuestion(body) {
  return await post(`/admin/questions`, { body })
}

export async function putQuestion({ id, ...body }) {
  return await put(`/admin/questions/${id}`, { body })
}

export async function delQuestion({ id }) {
  return await del(`/admin/questions/${id}`)
}

export async function getLesson({ id }) {
  return await get(`/admin/lessons/${id}`)
}

export async function getSubject({ id }) {
  return await get(`/admin/subjects/${id}`)
}

export async function postBlacklistedDomain(body) {
  return await post(`/admin/blacklisted-domains`, { body })
}

export async function delUser({ id }) {
  return await del(`/admin/users/${id}`)
}

export async function getSimulations({
  perPage = 20,
  page = 0,
  filters = [],
  sort,
  sortDir,
  query = "",
} = {}) {
  return await get(`/admin/simulations`, {
    queryObj: { perPage, page, sort, sortDir, query },
    query: filters,
  })
}

export async function getSimulation({ simulationId }) {
  return await get(`/admin/simulations/${simulationId}`)
}

export async function postSimulation(body) {
  return await post(`/admin/simulations`, { body })
}

export async function putSimulation({ id, ...body }) {
  return await put(`/admin/simulations/${id}`, { body })
}

export async function delSimulation({ id }) {
  return await del(`/admin/simulations/${id}`)
}

export async function postSimulationAsset(body) {
  return await post(`/admin/simulations-assets`, { body })
}

export async function delSimulationAsset(id) {
  return await del(`/admin/simulations-assets/${id}`)
}

export async function getBooks({
  perPage = 20,
  page = 0,
  filters = [],
  sort,
  sortDir,
  query = "",
} = {}){
  return await get(`/admin/books`, {
    queryObj: { perPage, page, sort, sortDir, query },
    query: filters,
  })
}

export async function getBook({ bookId }) {
  return await get(`/admin/books/${bookId}`)
}

export async function postBook(body) {
  return await post(`/admin/books`, { body })
}

export async function putBook({ id, ...body }) {
  return await put(`/admin/books/${id}`, { body })
}

export async function delBook({ id }) {
  return await del(`/admin/books/${id}`)
}

export async function getCaseGroups({
  perPage = 20,
  page = 0,
  filters = [],
  query = "",
} = {}) {
  return await get(`/admin/case-groups`, {
    queryObj: { perPage, page, query },
    query: filters,
  })
}

export async function getClinicalCases({
  perPage = 20,
  page = 0,
  filters = [],
  query = "",
  caseGroup = ""
} = {}) {
  return await get(`/admin/clinical-cases`, {
    queryObj: { perPage, page, query, caseGroup },
    query: filters,
  })
}

export async function postClinicalCase(body) {
  return await post(`/admin/clinical-cases`, {body})
}

export async function putClinicalCase({id, ...body}) {
  return await put(`/admin/clinical-cases/${id}`, {body})
}

export async function delClinicalCase(id) {
  return await del(`/admin/clinical-cases/${id}`)
}

export async function getCaseFrame(clinicalCase) {
  return await get(`/admin/case-frames`, {
    queryObj: { clinicalCase },
  })
}

export async function postCaseFrame(body) {
  return await post(`/admin/case-frames`, {body})
}

export async function postCaseFramesReorder({ caseId, caseFrameIds }) {
  return await post(`/admin/case-frames-reorder`, {
    queryObj: { caseId },
    body: { caseFrameIds },
  })
}

export async function putCaseFrame({id, ...body}) {
  return await put(`/admin/case-frames/${id}`, {body})
}

export async function delCaseFrame(id) {
  return await del(`/admin/case-frames/${id}`)
}

export async function getCaseFramesAnswers(caseFrame) {
  return await get(`/admin/case-frames-answers`, {
    queryObj: { caseFrame }
  })
}

export async function postCaseFrameAnswer(body) {
  return await post(`/admin/case-frames-answers`, {body})
}

export async function putCaseFrameAnswer({id, ...body}) {
  return await put(`/admin/case-frames-answers/${id}`, {body})
}

export async function delCaseFrameAnswer(id) {
  return await del(`/admin/case-frames-answers/${id}`)
}