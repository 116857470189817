<script>
  import { createEventDispatcher, onMount } from "svelte"

  import Button from "atoms/Button.svelte"
  import TextInput from "molecules/TextInput.svelte"
  import ImageInput from "atoms/ImageInput.svelte"
  import FileInput from "atoms/FileInput.svelte"
  import { mapErrors } from "utils/errors"
  import {
    getFileUrl,
    blobFromBase64,
    contentTypeFromBase64,
    isBase64,
  } from "utils/files"
  import * as API from "apis/backend"

  const dispatch = createEventDispatcher()

  let className = ""
  export { className as class }

  export let lesson
  export let topicId
  let id
  let name = ""
  let image
  let errors = {}

  onMount(function () {
    if (lesson) {
      id = lesson.id
      name = lesson.name || ""
      image =
        lesson.image &&
        getFileUrl(`/topics/${lesson.topicId}/lessons/${lesson.id}/image`)
    }
  })

  async function submit() {
    const apiFn = id ? API.putLesson : API.postLesson
    const successStatus = id ? 200 : 201
    const imageContentType = contentTypeFromBase64(image)
    const payload = {
      ...(id ? { id } : { topicId }),
      name,
      imageContentType,
    }
    const [data, meta] = await apiFn(payload)
    if (meta.status === successStatus) {
      if (isBase64(image) && data.imageUploadUrl)
        await fetch(data.imageUploadUrl, {
          method: "PUT",
          body: blobFromBase64(image),
          headers: { "Content-Type": imageContentType },
        })

      lesson = data
      return dispatch("success", lesson)
    }

    errors = mapErrors(data)
  }
</script>

<form class={className} on:submit|preventDefault={submit}>
  <TextInput label="Nome" class="mb-6" errors={errors.name} bind:value={name} />
  <ImageInput class="mb-6" bind:value={image} label="Imagem" />
  <Button>Salvar</Button>
</form>
