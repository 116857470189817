<script>
  import { createEventDispatcher } from "svelte"

  import * as API from "apis/backend"

  import CaseFrameAnswerForm from "./_CaseFrameAnswerForm.svelte"

  let className = ""
  export let caseFrameAnswer
  export { className as class }
  let editing = false

  const dispatch = createEventDispatcher()

  async function remove() {
    if (confirm("Tem certeza que quer remover a resposta?")) {
      await API.delCaseFrameAnswer(caseFrameAnswer.id)
      open = false
      dispatch("caseFrameAnswerDelete", caseFrameAnswer)
    }
  }

  function success({ detail: caseFrameAnswer }) {
    editing = false
    dispatch("CaseFrameAnswerUpdate", caseFrameAnswer)
  }
</script>

<div class={className}>
  <div class="flex items-center">
    <div
      class="relative flex items-center justify-center w-6 h-6 mr-2 bg-gray-500 rounded-md cursor-pointer">
      {#if caseFrameAnswer.isCorrect}
        <i class="pointer-events-none fa fa-check text-green-500" />
      {:else}<i class="pointer-events-none fa fa-times text-red-500" />{/if}
    </div>
    {caseFrameAnswer.content}
    {#if !editing}
      <span
        class="ml-2 cursor-pointer"
        on:click|preventDefault={() => (editing = true)}>
        <a href>editar</a>
      </span>
      <span class="ml-1 cursor-pointer" on:click|preventDefault={remove}><a
          href>remover</a></span>
    {:else}
      <div class="pl-16 mt-4">
        <div class="max-w-md px-4">
          <CaseFrameAnswerForm {caseFrameAnswer} on:success={success} />
        </div>
      </div>
      <span
        class="ml-2 cursor-pointer"
        on:click|preventDefault={() => (editing = false)}>
        <a href>cancelar</a>
      </span>
    {/if}
  </div>
</div>
