import { getAccessToken } from "stores/Auth"

const defaultHeaders = { "Content-Type": "application/json" }
const defaultUrl = process.env.API_URL

function objectToArray(obj) {
  if (!obj) return []
  return Object.entries(obj).map(([key, value]) => ({ key, value }))
}

async function request(args) {
  const method = args.method
  const body = args.body && JSON.stringify(args.body)
  const headers = { ...defaultHeaders }
  const query = objectToArray(args.queryObj)
  query.push(...(args.query || []))
  const queryString = query
    ? "?" +
      query
        .map((obj) => {
          return `${obj.key}=${obj.value}`
        })
        .join("&")
    : ""
  const url = `${defaultUrl}${args.url}${queryString}`
  if (!args.skipJWT) {
    const accessToken = await getAccessToken()
    if (accessToken) headers["Authorization"] = `bearer ${accessToken}`
  }
  const response = await fetch(url, { method, headers, body })
  let meta = {}
  meta.status = response.status
  if (response.headers.has("X-Total-Count"))
    meta.total = parseInt(response.headers.get("X-Total-Count"))

  const data = meta.status === 204 ? null : await response.json()

  return [data, meta]
}

export function get(url, args = {}) {
  return request({ url, method: "GET", ...args })
}

export function post(url, args = {}) {
  return request({ url, method: "POST", ...args })
}

export function put(url, args = {}) {
  return request({ url, method: "PUT", ...args })
}

export function patch(url, args = {}) {
  return request({ url, method: "PATCH", ...args })
}

export function del(url, args = {}) {
  return request({ url, method: "DELETE", ...args })
}
