<script>
  export let page = 0
  export let perPage = 25
  export let total = 0
  export let perPageOptions = [10, 25, 50]

  $: maxPage = Math.ceil(total / perPage) - 1
</script>

<style>
  .selected {
    @apply text-white bg-blue-600;
  }
</style>

<div class="flex mt-4">
  <div class="mr-auto">
    Items por página:
    {#each perPageOptions as perPageOption}
      <span
        class="inline-block px-2 py-1 ml-1 mr-1 text-center text-blue-600 border border-blue-600 rounded-sm cursor-pointer"
        class:selected={perPage === perPageOption}
        on:click={() => {
          page = 0
          perPage = perPageOption
        }}>
        {perPageOption}
      </span>
    {/each}

  </div>
  <div class="ml-auto">
    <span class="mr-2">Total de items: {total}</span>
    <span
      class="px-3 py-1 mr-1 text-white bg-blue-600 rounded-sm cursor-pointer"
      on:click={() => {
        if (page > 0) page = page - 1
      }}>
      <i class="fa fa-chevron-left" />
    </span>
    <span class="px-2 py-1 mr-1 cursor-default">{page + 1}</span>
    <span
      class="px-3 py-1 text-white bg-blue-600 rounded-sm cursor-pointer"
      on:click={() => {
        if (page < maxPage) page = page + 1
      }}>
      <i class="fa fa-chevron-right" />
    </span>
  </div>
</div>
