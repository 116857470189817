<script>
  let className
  export let color
  export { className as class }
</script>

<style>
  .green {
    @apply bg-green-500;
  }
  .red {
    @apply bg-red-500;
  }
  .yellow {
    @apply bg-yellow-500;
  }
</style>

<span class="inline-block w-3 h-3 rounded-full {color} {className}" on:click />
