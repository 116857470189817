<script>
  import { onMount } from "svelte"
  import { url } from "@sveltech/routify"
  import Input from "atoms/Input.svelte"
  import TableHead from "atoms/TableHead.svelte"
  import ButtonLink from "atoms/ButtonLink.svelte"
  import CheckboxGroup from "molecules/CheckboxGroup.svelte"
  import FileInput from "atoms/FileInput.svelte"
  import Pager from "molecules/Pager.svelte"
  import * as API from "apis/backend"
  import { formatDateTime } from "utils/date"
  import { blobFromBase64, getFileUrl, getMime } from "utils/files"
  import hash from "utils/hash"

  let page = 0
  let perPage = 25
  let query = ""
  let filters = []
  let sort = "s.title"
  let sortDir = "DESC"

  let uploaderValue
  let uploaderMimeType
  let uploading = false
  let total = 0
  let simulations = []
  let lastReqHash = null

  function resetPage() {
    page = 0
  }

  $: filters, query, (() => resetPage())()

  $: payload = {
    page,
    perPage,
    filters,
    sort,
    sortDir,
    query,
  }

  $: (async function load() {
    const reqHash = hash(payload)
    if (!lastReqHash || lastReqHash !== reqHash) {
      lastReqHash = reqHash
      const [data, meta] = await API.getSimulations(payload)
      if (lastReqHash === reqHash) {
        simulations = data
        total = meta.total
      }
    }
  })()

  async function uploadAsset(simulationIdx) {
    uploading = true
    const simulation = simulations[simulationIdx]
    const simulationId = simulation.id
    const mimeType = uploaderMimeType || "application/octet-stream"
    const payload = { simulationId, mimeType }
    const [asset, meta] = await API.postSimulationAsset(payload)
    if (meta.status === 201) {
      await fetch(asset.uploadUrl, {
        method: "PUT",
        body: blobFromBase64(uploaderValue),
        headers: { "Content-Type": mimeType },
      })
    }
    simulations[simulationIdx].assets.push(asset)
    simulations = simulations
    uploaderMimeType = ""
    uploaderValue = null
    uploading = false
  }

  async function deleteAsset(simulationIdx, assetId) {
    if (confirm("Tem certeza que deseja remover esse recurso?")) {
      await API.delSimulationAsset(assetId)
      const idx = simulations[simulationIdx].assets.findIndex(
        (x) => x.id === assetId
      )
      simulations[simulationIdx].assets.splice(idx, 1)
      simulations = simulations
    }
  }

  async function deleteSimulation(simulation) {
    if (confirm("Tem certeza que deseja remover essa simulação?")) {
      await API.delSimulation(simulation)
      const idx = simulations.findIndex((x) => x.id === simulation.id)
      simulations.splice(idx, 1)
      simulations = simulations
    }
  }
</script>

<div class="flex p-4">
  {#if lastReqHash !== null}
    <div class="flex flex-col flex-grow">
      <div class="flex-grow p-4 ml-4 bg-white rounded-md shadow-md">
        <div class="flex mb-4">
          <Input
            placeholder="Busca por título."
            debounce={true}
            bind:value={query}
            class="w-full mr-4 flex-grow-1" />
          <ButtonLink class="flex-grow-0 flex-shrink-0" href="/simulacoes/nova">
            Nova Simulação
          </ButtonLink>
        </div>
        <table class="w-full table-fixed">
          <thead>
            <tr>
              <TableHead
                class="px-4 py-2"
                sortKey="s.title"
                bind:sort
                bind:sortDir>
                Título
              </TableHead>
              <TableHead
                class="px-4 py-2"
                sortKey="s.title"
                bind:sort
                bind:sortDir>
                Tópico
              </TableHead>
              <TableHead
                class="px-4 py-2"
                sortKey="assetsCount"
                bind:sort
                bind:sortDir>
                Recursos
              </TableHead>
              <th class="w-16" />
            </tr>
          </thead>
          <tbody>
            {#each simulations as simulation, i}
              <tr>
                <td class="px-4 py-2 truncate border">{simulation.title}</td>
                <td class="px-4 py-2 truncate border">
                  {simulation.topic.name}
                </td>
                <td class="px-4 py-2 truncate border">
                  <div class="flex items-center">
                    {#each simulation.assets as asset}
                      {#if asset.mimeType === 'application/octet-stream'}
                        <a
                          href={getFileUrl(`/simulations/${simulation.id}/assets/${asset.id}`)}
                          target="_blank">
                          <div
                            class="flex items-center justify-center w-16 h-16 mr-1 overflow-hidden text-2xl text-white bg-blue-500 rounded-md flex-0">
                            <i class="fas fa-cube" />
                          </div>
                        </a>
                      {:else}
                        <img
                          alt="Asset"
                          src={getFileUrl(`/simulations/${simulation.id}/assets/${asset.id + '.' + getMime(asset.mimeType)}`)}
                          target="_blank"
                          class="w-16 h-16 mr-1 overflow-hidden rounded-md" />
                      {/if}
                      <span
                        on:click={() => deleteAsset(i, asset.id)}
                        class="flex items-center justify-center w-8 h-8 mr-4 overflow-hidden text-sm text-white bg-red-500 rounded-md cursor-pointer flex-0">
                        <i class="fa fa-trash" />
                      </span>
                    {/each}
                    {#if !uploading}
                      <FileInput
                        on:change={() => uploadAsset(i)}
                        bind:value={uploaderValue}
                        bind:contentType={uploaderMimeType}
                        label="" />
                    {:else}
                      <div
                        class="flex items-center justify-center w-16 h-16 mr-2 overflow-hidden text-2xl text-white bg-blue-500 rounded-md flex-0">
                        <i class="fas fa-circle-notch fa-spin" />
                      </div>
                    {/if}
                  </div>
                </td>
                <td class="px-2 py-2 text-right border">
                  <a
                    href={$url(`../:simulationId/editar`, {
                      simulationId: simulation.id,
                    })}>
                    <i class="cursor-pointer fa fa-pencil" />
                  </a>
                  <span
                    on:click={() => deleteSimulation(simulation)}
                    class="ml-2">
                    <i class="cursor-pointer fa fa-trash" />
                  </span>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
        <Pager {total} bind:page bind:perPage />
      </div>
    </div>
  {/if}
</div>
