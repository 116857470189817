<script>
  import * as API from "apis/backend"
  import { secondsToMinutes } from "utils/date"
  import { formatRating } from "utils/rating"

  export let lessonId
  let subjects = []

  async function load() {
    ;[subjects] = await API.getLessonSubjects({ lessonId })
  }
  $: lessonId, load()
</script>

{#each subjects as subject}
  <div class="flex flex-col border-b border-gray-300 py-2 mb-2">
    <div class="flex items-center">
      <div class="mr-auto">
        <i class="fas fa-video mr-1" />
        {subject.name}
      </div>
      {#if subject.averageRating !== null}
        <div
          class="h-6 px-2 rounded-sm flex justify-center items-center mr-2
          bg-gray-500 text-white"
          class:bg-red-500={subject.averageRating < 3}>
          <i class="fas fa-star mr-1" />
          {formatRating(subject.averageRating)}
        </div>
      {/if}
      <div
        class="h-6 px-2 rounded-sm flex justify-center items-center mr-2
        bg-gray-500 text-white"
        class:bg-red-500={subject.questionsCount < 10}>
        <i class="fas fa-question mr-1" />
        {subject.questionsCount}
      </div>
      <div
        class="h-6 px-2 rounded-sm flex justify-center items-center mr-2
        bg-gray-500 text-white">
        <i class="fas fa-clock mr-1" />
        {secondsToMinutes(subject.duration)}
      </div>
    </div>
  </div>
{/each}
