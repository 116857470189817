<script>
  import { goto } from "@sveltech/routify"
  import { isLoggedIn, deauth } from "stores/Auth"
  import Logo from "atoms/Logo.svelte"

  let menuOpen = window.innerWidth > 640
</script>

<style>
  .wrapper {
    @apply pt-20;
  }
  .menu {
    @apply bg-white w-64 shadow-md pt-4 left-0 fixed flex-shrink-0 hidden flex-col items-center;
    top: 5rem;
    height: calc(100vh - 5rem);
  }
  .menu-open {
    @apply flex;
  }
  .menu-item {
    @apply flex flex-row items-center text-xl w-full px-4 py-4 text-gray-900 cursor-pointer;
  }
  .menu-item:hover {
    @apply bg-gray-300;
  }
  .icon-wrapper {
    @apply flex flex-shrink-0 justify-center items-center w-8 h-8 mr-4;
  }
  .icon-wrapper i {
    @apply cursor-pointer;
  }
  @screen sm {
    .menu-item {
      @apply px-8;
    }
    .wrapper-open {
      @apply pl-64;
    }
  }
</style>

{#if $isLoggedIn}
  <div class="wrapper" class:wrapper-open={menuOpen}>
    <div
      class="fixed top-0 left-0 z-10 flex flex-row items-center w-full h-20 px-4 text-xl bg-white shadow-md sm:px-8">
      <div
        class="icon-wrapper"
        on:click={() => {
          menuOpen = !menuOpen
        }}>
        <i class="fas fa-bars" />
      </div>
      <Logo class="flex-shrink-0 w-40 h-auto" />
    </div>
    <aside class="menu" class:menu-open={menuOpen}>
      <a href="/" class="menu-item">
        <div class="icon-wrapper"><i class="fas fa-home" /></div>
        Início
      </a>
      <a href="/historico" class="border-b border-gray-300 menu-item">
        <div class="icon-wrapper"><i class="fas fa-chart-line" /></div>
        Histórico
      </a>
      <a href="/usuarios" class="menu-item">
        <div class="icon-wrapper"><i class="fas fa-user" /></div>
        Usuários
      </a>
      <a href="/pedidos" class="menu-item">
        <div class="icon-wrapper"><i class="fas fa-money-bill-wave" /></div>
        Pedidos
      </a>
      <a href="/cupons" class="border-b border-gray-300 menu-item">
        <div class="icon-wrapper"><i class="fas fa-tag" /></div>
        Cupons
      </a>
      <a href="/topicos" class="menu-item">
        <div class="icon-wrapper"><i class="fas fa-list-ul" /></div>
        Tópicos
      </a>
      <a href="/perguntas" class="menu-item">
        <div class="icon-wrapper"><i class="fas fa-question" /></div>
        Perguntas
      </a>
      <a href="/livros" class="menu-item">
        <div class="icon-wrapper"><i class="fas fa-book-open" /></div>
        Livros
      </a>
      <a href="/materiais" class="menu-item">
        <div class="icon-wrapper"><i class="fas fa-book" /></div>
        Materiais
      </a>
      <a href="/simulacoes" class="menu-item">
        <div class="icon-wrapper"><i class="fas fa-cube" /></div>
        Simulações
      </a>
      <a href="/casos" class="menu-item">
        <div class="icon-wrapper"><i class="fas fa-cube" /></div>
        Casos Clínicos
      </a>
      <a href="/universidades" class="border-b border-gray-300 menu-item">
        <div class="icon-wrapper"><i class="fas fa-cube" /></div>
        Universidades
      </a>
      <span on:click|preventDefault={deauth} class="menu-item">
        <div class="icon-wrapper"><i class="fas fa-sign-out-alt" /></div>
        Sair
      </span>
    </aside>
    <slot />
  </div>
{:else}{$goto('/entrar')}{/if}
