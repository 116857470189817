import { format as formatFn } from "date-fns"

export const FORMAT_DATE_DISPLAY = "dd/MM/yyyy"
export const FORMAT_DATE_API = "yyyy-MM-dd"

export function formatDate(date, { format = FORMAT_DATE_DISPLAY } = {}) {
  return formatFn(new Date(date), format)
}

export function formatDateTime(datetime) {
  return formatFn(new Date(datetime), "dd/MM/yyyy HH:mm")
}

export function secondsToMinutes(v) {
  const min = Math.floor(v / 60).toString()
  const sec = (v % 60).toString()
  return `${min}:${sec.padStart(2, "0")}`
}
