import { get } from "utils/api"

export async function getDayRevenue({ date }) {
  const queryObj = { date }
  return await get(`/admin/data-points/day-revenue`, { queryObj })
}

export async function getDayRegistrations({ date }) {
  const queryObj = { date }
  return await get(`/admin/data-points/day-registrations`, { queryObj })
}

export async function getDayPayments({ date }) {
  const queryObj = { date }
  return await get(`/admin/data-points/day-payments`, { queryObj })
}

function reduceIntValues(obj) {
  return Object.keys(obj).reduce(
    (acc, key) => ({ [key]: parseInt(obj[key]) || 0, ...acc }),
    {}
  )
}

export async function getRangePayments({ fromDate, toDate, interval }) {
  const path = `/admin/data-points/range-payments`
  const queryObj = { fromDate, toDate, interval }
  const [_data, meta] = await get(path, { queryObj })
  const data = reduceIntValues(_data)
  return [data, meta]
}

export async function getRangeRegistrations({ fromDate, toDate, interval }) {
  const path = `/admin/data-points/range-registrations`
  const queryObj = { fromDate, toDate, interval }
  const [_data, meta] = await get(path, { queryObj })
  const data = reduceIntValues(_data)
  return [data, meta]
}

export async function getCurrUsers() {
  return await get(`/admin/data-points/curr-users`)
}

export async function getCurrBundles() {
  return await get(`/admin/data-points/curr-bundles`)
}

export async function getCurrUsersByField() {
  return await get(`/admin/data-points/curr-users-by-field`)
}

export async function getCurrBundlesByField() {
  return await get(`/admin/data-points/curr-bundles-by-field`)
}
