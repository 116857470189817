<script>
  let className = ""
  export let options = []
  export let optional = true
  export let value
  export let errors = false
  export let placeholder = ""
  export { className as class }
</script>

<style>
  .select {
    @apply w-full h-10 px-2 text-gray-800 bg-white border border-gray-400 rounded-sm shadow-sm outline-none;
    -webkit-appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, black 50%),
      linear-gradient(135deg, black 50%, transparent 50%);
    background-position: calc(100% - 1rem) 1rem, calc(100% - 0.7rem) 1rem;
    background-repeat: no-repeat;
    background-size: 0.3rem 0.3rem, 0.3rem 0.3rem;
  }
  .select:focus {
    @apply border-gray-500;
  }
  .error,
  .error:focus {
    @apply border-red-500;
  }
</style>

<select class="select {className}" class:error={errors} bind:value>
  {#if optional}
    <option>{placeholder}</option>
  {/if}
  {#each options as option}
    <option value={option.value}>{option.label}</option>
  {/each}
</select>
