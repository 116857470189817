<script>
  let className = ""
  export { className as class }
</script>

<button
  class={`block w-full rounded-sm text-lg leading-10 bg-blue-700 text-white
  cursor-pointer ${className}`}
  on:click>
  <slot />
</button>
