<script>
  import { url, goto } from "@sveltech/routify"
  import { onMount } from "svelte"

  import Button from "atoms/Button.svelte"
  import TextInput from "molecules/TextInput.svelte"
  import TextAreaInput from "molecules/TextAreaInput.svelte"
  import { mapErrors } from "utils/errors"
  import SelectWithLabel from "molecules/SelectWithLabel.svelte"

  import * as API from "apis/backend"

  export let simulation
  export let id
  export let title
  export let description
  export let topicId
  let errors = {}

  const optionsMapper = ({ id: value, name: label }) => ({
    value,
    label,
  })

  let fetching = true
  let topicOptions = []
  onMount(async function () {
    if (simulation) {
      id = simulation.id
      title = simulation.title
      description = simulation.description
      topicId = simulation.topicId
    }

    const [topics] = await API.getTopics({ perPage: 1000 })
    topicOptions = topics.map(optionsMapper)

    if (!topics[0]) return
    if (!topicId) topicId = topics[0].id

    fetching = false
  })

  async function submit() {
    const apiFn = id ? API.putSimulation : API.postSimulation
    const successStatus = id ? 200 : 201
    const payload = {
      ...(id ? { id } : {}),
      title,
      description,
      topicId,
    }
    const [data, meta] = await apiFn(payload)
    if (meta.status === successStatus) {
      return $goto("/simulacoes")
    }

    errors = mapErrors(data)
  }
</script>

<div class="flex p-4">
  <div class="flex flex-col flex-grow">
    <div class="flex-grow p-4 ml-4 bg-white rounded-md shadow-md">
      <a href={$url('/simulacoes')}>
        <i class="mb-4 text-lg cursor-pointer fas fa-arrow-left" />
      </a>
      <form on:submit|preventDefault={submit}>
        <TextInput
          label="Título"
          class="mb-6"
          errors={errors.title}
          bind:value={title} />
        <TextAreaInput
          label="Descriçã"
          class="mb-6"
          errors={errors.description}
          bind:value={description} />
        {#if !fetching}
          <SelectWithLabel
            label="Tópico"
            class="mb-6"
            optional={false}
            options={topicOptions}
            bind:value={topicId} />
        {/if}
        <Button>Salvar</Button>
      </form>
    </div>
  </div>
</div>
