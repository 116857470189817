<script>
  import Label from "atoms/Label.svelte"
  import Select from "atoms/Select.svelte"
  import Errors from "atoms/Errors.svelte"

  let className
  export let label
  export let errors
  export let value
  export let options = []
  export let optional = true
  export let placeholder = ""
  export { className as class }
</script>

<div class={className}>
  <Label {errors} class="mb-1">{label}</Label>
  <Select bind:value {errors} {options} {optional} {placeholder} />
  <Errors {errors} class="mt-1" />
</div>
