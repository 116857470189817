<script>
  import Form from "../_Form.svelte"

  import * as API from "apis/backend"
  import { getImageUrl } from "utils/image"

  export let universityId
  let university
  let fetching

  async function load() {
    fetching = true
    ;[university] = await API.getUniversity({ universityId })
    const { id, image } = university
    if (image) university.image = getImageUrl(`/universities/${id}/image`)
    university.published = university.published || false
    fetching = false
  }

  $: universityId, load()
</script>

{#if !fetching}
  <Form {university} />
{/if}
