<script>
  import { onMount } from "svelte"
  import { url } from "@sveltech/routify"
  import Input from "atoms/Input.svelte"
  import TableHead from "atoms/TableHead.svelte"
  import ButtonLink from "atoms/ButtonLink.svelte"
  import CheckboxGroup from "molecules/CheckboxGroup.svelte"
  import Pager from "molecules/Pager.svelte"
  import * as API from "apis/backend"
  import { formatDateTime } from "utils/date"
  import hash from "utils/hash"

  const publishedFilterOptions = [
    {
      key: "published",
      value: false,
      label: "Rascunho",
      statusColor: "yellow"
    },
    {
      key: "published",
      value: true,
      label: "Publicado",
      statusColor: "green"
    }
  ]

  const imageFilterOptions = [
    {
      key: "image",
      value: false,
      label: "Ausente",
      statusColor: "yellow"
    },
    {
      key: "image",
      value: true,
      label: "Presente",
      statusColor: "green"
    }
  ]

  const allocationFilterOptions = [
    { key: "orphan", value: true, label: "Orfão", statusColor: "yellow" },
    { key: "allocated", value: true, label: "Alocado", statusColor: "green" }
  ]

  let page = 0
  let perPage = 25
  let query = ""
  let filters = []
  let sort = "q.title"
  let sortDir = "DESC"

  let total = 0
  let questions = []
  let lastReqHash = null

  function resetPage() {
    page = 0
  }

  $: filters, query, (() => resetPage())()

  $: payload = {
    page,
    perPage,
    filters,
    sort,
    sortDir,
    query
  }

  $: (async function load() {
    const reqHash = hash(payload)
    if (!lastReqHash || lastReqHash !== reqHash) {
      lastReqHash = reqHash
      const [data, meta] = await API.getQuestions(payload)
      if (lastReqHash === reqHash) {
        questions = data
        total = meta.total
      }
    }
  })()

  async function remove(question) {
    if (confirm("Tem certeza que quer remover a pergunta?")) {
      await API.delQuestion(question)
      const idx = questions.findIndex(x => x.id === question.id)
      questions.splice(idx, 1)
      questions = questions
    }
  }
</script>

<div class="flex p-4">
  <div class="flex flex-col flex-shrink-0 w-56 bg-white rounded-md shadow-md">
    <div class="px-6 py-6 border-b border-gray-300">
      <CheckboxGroup
        label="Publicação"
        options={publishedFilterOptions}
        bind:group={filters} />
    </div>
    <div class="px-6 py-6 border-b border-gray-300">
      <CheckboxGroup
        label="Imagem"
        options={imageFilterOptions}
        bind:group={filters} />
    </div>
    <div class="px-6 py-6 border-b border-gray-300">
      <CheckboxGroup
        label="Alocação"
        options={allocationFilterOptions}
        bind:group={filters} />
    </div>
  </div>
  {#if lastReqHash !== null}
    <div class="flex flex-col flex-grow">
      <div class="flex-grow p-4 ml-4 bg-white rounded-md shadow-md">
        <div class="flex mb-4">
          <Input
            placeholder="Busca por enunciado, assunto, módulo ou tópico."
            debounce={true}
            bind:value={query}
            class="w-full mr-4 flex-grow-1" />
          <ButtonLink class="flex-grow-0 flex-shrink-0" href={$url('../nova')}>
            Nova Pergunta
          </ButtonLink>
        </div>
        <table class="w-full table-fixed">
          <thead>
            <tr>
              <TableHead
                class="px-4 py-2"
                sortKey="q.title"
                bind:sort
                bind:sortDir>
                Enunciado
              </TableHead>
              <TableHead
                class="w-48 px-4 py-2"
                sortKey="t.name"
                bind:sort
                bind:sortDir>
                Tópico
              </TableHead>
              <TableHead
                class="w-48 px-4 py-2"
                sortKey="l.name"
                bind:sort
                bind:sortDir>
                Módulo
              </TableHead>
              <TableHead
                class="w-48 px-4 py-2"
                sortKey="s.name"
                bind:sort
                bind:sortDir>
                Assunto
              </TableHead>
              <TableHead
                class="w-48 px-4 py-2"
                sortKey="q.updatedAt"
                bind:sort
                bind:sortDir>
                Data Atualização
              </TableHead>
              <th class="w-16" />
            </tr>
          </thead>
          <tbody>
            {#each questions as question}
              <tr>
                <td class="px-4 py-2 truncate border" title={question.title}>
                  {question.title}
                </td>
                <td class="px-4 py-2 truncate border">
                  {#if question.subject}
                    {question.subject.lesson.topic.name}
                  {/if}
                </td>
                <td class="px-4 py-2 truncate border">
                  {#if question.subject}{question.subject.lesson.name}{/if}
                </td>
                <td class="px-4 py-2 truncate border">
                  {#if question.subject}{question.subject.name}{/if}
                </td>
                <td class="px-4 py-2 truncate border">
                  {formatDateTime(question.updatedAt)}
                </td>
                <td class="px-2 py-2 text-center border">
                  <a href={$url(`../${question.id}/editar`)}>
                    <i class="cursor-pointer fa fa-pencil" />
                  </a>
                  <span class="ml-2" on:click={() => remove(question)}>
                    <i class="cursor-pointer fa fa-times" />
                  </span>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
        <Pager {total} bind:page bind:perPage />
      </div>
    </div>
  {/if}
</div>
