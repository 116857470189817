<script>
  import { onMount } from "svelte"
  import DataBox from "molecules/DataBox.svelte"
  import DataPieBox from "molecules/DataPieBox.svelte"
  import { formatMoney } from "utils/money"
  import { formatPercentage } from "utils/math"
  import { formatDate, FORMAT_DATE_API } from "utils/date"
  import * as API from "apis/analytics"

  const date = formatDate(new Date(), { format: FORMAT_DATE_API })

  let dayRevenueReq = API.getDayRevenue({ date })
  let dayRegistrationsReq = API.getDayRegistrations({ date })
  let dayPaymentsReq = API.getDayPayments({ date })
  let dayConversionReq = (async () => {
    const [dayPayments] = await dayPaymentsReq
    const [dayRegistrations] = await dayRegistrationsReq
    const current = dayPayments.current / dayRegistrations.current
    const previous = dayPayments.previous / dayRegistrations.previous
    const delta = current / previous
    return [{ current, previous, delta }]
  })()

  let currUsersReq = API.getCurrUsers()
  let currBundlesReq = API.getCurrBundles()
  let currUsersByFieldReq = API.getCurrUsersByField()
  let currBundlesByFieldReq = API.getCurrBundlesByField()
</script>

<style>
  .wrapper {
    @apply grid w-full grid-cols-1 gap-4 p-4 max-w-6xl;
  }

  @screen sm {
    .wrapper {
      @apply p-8 grid-cols-2;
    }
  }

  @screen lg {
    .wrapper {
      @apply grid-cols-3;
    }
  }
</style>

<div class="wrapper">
  <div class="col-span-1">
    {#await dayRevenueReq then [dayRevenue]}
      <DataBox
        title="Receita"
        current={formatMoney(dayRevenue.current)}
        delta={dayRevenue.delta} />
    {/await}
  </div>
  <div class="col-span-1">
    {#await dayRegistrationsReq then [dayRegistrations]}
      <DataBox
        title="Registros"
        current={dayRegistrations.current}
        delta={dayRegistrations.delta} />
    {/await}
  </div>
  <div class="col-span-1">
    {#await dayPaymentsReq then [dayPayments]}
      <DataBox
        title="Pagamentos"
        current={dayPayments.current}
        delta={dayPayments.delta} />
    {/await}
  </div>
  <div class="col-span-1">
    {#await dayConversionReq then [dayConversion]}
      <DataBox
        title="Conversão"
        current={formatPercentage(dayConversion.current)}
        delta={dayConversion.delta} />
    {/await}
  </div>
  <div class="col-span-1">
    {#await currUsersReq then [users]}
      <DataBox title="Usuários Totais" current={users.total} />
    {/await}
  </div>
  <div class="col-span-1">
    {#await currBundlesReq then [bundles]}
      <DataBox title="Planos Ativos" current={bundles.total} />
    {/await}
  </div>
  <div class="col-span-3">
    {#await currUsersByFieldReq then [usersPerField]}
      <DataPieBox title="Usuários por Curso" groups={usersPerField} />
    {/await}
  </div>
  <div class="col-span-3">
    {#await currBundlesByFieldReq then [bundlesPerField]}
      <DataPieBox title="Planos Ativos por Curso" groups={bundlesPerField} />
    {/await}
  </div>
</div>
