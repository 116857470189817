import { writable } from "svelte/store"
import jwtDecode from "jwt-decode"
import { isPast } from "date-fns"
import * as API from "apis/backend"

const KEY = "ibselAdminTokens"

const isLoggedIn = writable(false)
const currentUser = writable(null)

let user = null
let accessToken = null
let refreshToken = null
let refreshRequest = null

function getUserFromJWT() {
  return {
    id: jwtDecode(accessToken).id,
    email: jwtDecode(accessToken).email,
  }
}

try {
  const initialState = JSON.parse(localStorage.getItem(KEY))
  if (initialState) {
    accessToken = initialState.accessToken
    refreshToken = initialState.refreshToken
    isLoggedIn.set(true)
    user = getUserFromJWT(initialState.accessToken)
    currentUser.set(user)
  }
} catch {}

export function auth(tokens) {
  localStorage.setItem(KEY, JSON.stringify(tokens))
  accessToken = tokens.accessToken
  refreshToken = tokens.refreshToken
  isLoggedIn.set(true)
  user = getUserFromJWT(tokens.accessToken)
  currentUser.set(getUserFromJWT(user))
}

export function deauth() {
  localStorage.removeItem(KEY)
  accessToken = null
  refreshToken = null
  isLoggedIn.set(false)
  user = null
  currentUser.set(null)
  window.location = "/"
}

export async function refreshAccessToken() {
  if (refreshRequest) {
    return async function () {
      const [data, { status }] = await refreshRequest
      if (status === 200) return data.accessToken
      deauth()
    }
  }
  refreshRequest = API.postTokens({ email: user.email, refreshToken })
  const [data, { status }] = await refreshRequest
  if (status === 200) {
    auth(data)
    refreshRequest = null
    return accessToken
  } else {
    deauth()
    refreshRequest = null
  }
}

export async function getAccessToken() {
  if (!accessToken) return null
  const expiresAt = new Date(jwtDecode(accessToken).exp * 1000)
  if (isPast(expiresAt)) await refreshAccessToken()
  return accessToken
}

export { currentUser, isLoggedIn }
