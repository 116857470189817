<script>
  let className
  export let checked
  export let key
  export let value
  export let group = []
  export { className as class }

  $: updateCheckbox(group)
  $: updateGroup(checked)

  function updateCheckbox(group) {
    if (group.length > 0) {
      checked = group.findIndex(x => x.key === key && x.value === value) >= 0
    }
  }

  function updateGroup(checked) {
    const index = group.findIndex(x => x.key === key && x.value === value)
    if (checked) {
      if (index < 0) {
        group.push({ key, value })
        group = group
      }
    } else {
      if (index >= 0) {
        group.splice(index, 1)
        group = group
      }
    }
  }
</script>

<div class="flex items-center cursor-pointer relative {className}">
  <input
    type="checkbox"
    class="absolute w-full h-full opacity-0 cursor-pointer"
    {value}
    bind:checked />
  <i
    class={checked ? 'fas fa-check-square text-blue-600' : 'far fa-square text-gray-600'} />
  <span class="ml-2 text-gray-800">
    <slot />
  </span>
</div>
