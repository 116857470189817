<script>
  import Form from "../_Form.svelte"

  import * as API from "apis/backend"
  import { getImageUrl } from "utils/image"

  export let questionId
  let question
  let fetching

  async function load() {
    fetching = true
    ;[question] = await API.getQuestion({ questionId })
    const { id, image } = question
    if (image) question.image = getImageUrl(`/questions/${id}/image`)
    question.published = question.published || false
    fetching = false
  }

  $: questionId, load()
</script>

{#if !fetching}
  <Form {...question} />
{/if}
