<script>
  import { onMount } from "svelte"
  import { url } from "@sveltech/routify"
  import Input from "atoms/Input.svelte"
  import TableHead from "atoms/TableHead.svelte"
  import ButtonLink from "atoms/ButtonLink.svelte"
  import Pager from "molecules/Pager.svelte"
  import * as API from "apis/backend"
  import { formatDateTime } from "utils/date"
  import hash from "utils/hash"

  let page = 0
  let perPage = 25
  let query = ""
  let filters = []
  let sort = "c.name"
  let sortDir = "DESC"

  let total = 0
  let universities = []
  let lastReqHash = null

  function resetPage() {
    page = 0
  }

  $: filters, query, (() => resetPage())()

  $: payload = {
    page,
    perPage,
    filters,
    sort,
    sortDir,
    query,
  }

  $: (async function load() {
    const reqHash = hash(payload)
    if (!lastReqHash || lastReqHash !== reqHash) {
      lastReqHash = reqHash
      const [data, meta] = await API.getUniversities(payload)
      if (lastReqHash === reqHash) {
        universities = data
        total = meta.total
      }
    }
  })()

  async function remove(university) {
    if (confirm("Tem certeza que quer remover a universidade?")) {
      await API.delUniversity(university)
      const idx = universities.findIndex((x) => x.id === university.id)
      universities.splice(idx, 1)
      universities = universities
    }
  }
</script>

<div class="flex p-4">
  {#if lastReqHash !== null}
    <div class="flex flex-col flex-grow">
      <div class="flex-grow p-4 ml-4 bg-white rounded-md shadow-md">
        <div class="flex mb-4">
          <Input
            placeholder="Busca por Nome."
            debounce={true}
            bind:value={query}
            class="w-full mr-4 flex-grow-1" />
          <ButtonLink
            class="flex-grow-0 flex-shrink-0"
            href="/universidades/novo">
            Novo Cadastro
          </ButtonLink>
        </div>
        <table class="w-full table-fixed">
          <thead>
            <tr>
              <TableHead
                class="px-4 py-2"
                sortKey="c.name"
                bind:sort
                bind:sortDir>
                Nome
              </TableHead>
              <TableHead
                class="px-4 py-2"
                sortKey="c.cnpj"
                bind:sort
                bind:sortDir>
                CNPJ
              </TableHead>
              <TableHead
                class="w-48 px-4 py-2"
                sortKey="c.subdomain"
                bind:sort
                bind:sortDir>
                Subdomain
              </TableHead>
              <TableHead
                class="px-4 py-2"
                sortKey="c.integrationSecret"
                bind:sort
                bind:sortDir>
                Secret
              </TableHead>
              <TableHead
                class="px-4 py-2"
                sortKey="c.integrationKey"
                bind:sort
                bind:sortDir>
                Key
              </TableHead>
              <th class="w-16" />
            </tr>
          </thead>
          <tbody>
            {#each universities as university}
              <tr>
                <td class="px-4 py-2 truncate border">{university.name}</td>
                <td class="px-4 py-2 truncate border">{university.cnpj}</td>
                <td class="px-4 py-2 truncate border">
                  {university.subdomain}
                </td>
                <td class="px-4 py-2 truncate border">
                  {university.integrationSecret}
                </td>
                <td class="px-4 py-2 truncate border">
                  {university.integrationKey}
                </td>
                <td class="px-2 py-2 text-right border">
                  <a href={$url(`/universidades/${university.id}/editar`)}>
                    <i class="cursor-pointer fa fa-pencil" />
                  </a>
                  <span class="ml-2" on:click={() => remove(university)}>
                    <i class="cursor-pointer fa fa-times" />
                  </span>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
        <Pager {total} bind:page bind:perPage />
      </div>
    </div>
  {/if}
</div>
