<script>
  import { onMount, createEventDispatcher } from "svelte"
  import { isBase64 } from "utils/image"

  const dispatch = createEventDispatcher()

  let className = ""
  let hash = ""
  export let label = ""
  export let value
  export let contentType
  export { className as class }

  onMount(() => {
    hash = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 15)
  })

  function change(evt) {
    const file = evt.target.files[0]
    contentType = file.type
    const reader = new FileReader()
    reader.addEventListener(
      "load",
      function () {
        value = this.result
        dispatch("change", value)
      },
      false
    )
    reader.readAsDataURL(file)
  }
</script>

<style>
  input[type="file"]::-webkit-file-upload-button {
    display: none;
  }
</style>

<div class="flex items-center relative {className}">
  {#if value}
    <div
      class="flex items-center justify-center w-16 h-16 mr-2 overflow-hidden text-2xl text-white bg-blue-500 rounded-md flex-0">
      <i class="fas fa-file" />
    </div>
  {:else}
    <div
      class="flex items-center justify-center w-16 h-16 mr-2 text-2xl text-white bg-gray-500 rounded-md flex-0">
      <i class="fas fa-upload" />
    </div>
  {/if}
  <input
    type="file"
    class="absolute w-full h-full opacity-0 cursor-pointer"
    on:change={change} />
  <div class="flex-1">{label}</div>
</div>
